import logo from "styles/assets/loading/logo.webp";
export default function Loading({ percent }) {
  const WIDTH = 200;
  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen"
      style={{ backgroundColor: "#302929", zIndex: 1000 }}
    >
      <div className="w-full h-full relative flex justify-center items-center">
        <div style={{ width: `${WIDTH}px` }}>
          <img
            src={logo}
            className="block mx-auto"
            alt="monsterra logo"
            width="606"
            height="222"
          />
          <div
            className="rounded-md relative w-full h-2 overflow-hidden mt-3"
            style={{ backgroundColor: "#443e3e" }}
          >
            <div
              className=" h-full absolute top-0 left-0 duration-300 transition-all"
              style={{
                backgroundColor: "#fab526",
                width: `${percent * WIDTH}px`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import Header from "layout/Header";
import Social from "layout/Social";
import "./index.css";
import Main from "layout/Main";
import Footer from "layout/Footer";
import Loading from "./components/Loading";
import ReactGA from "react-ga";
import Tutorial from "components/Tutorial";
import Popup from "components/popup";
ReactGA.initialize("UA-214554139-1");
const MAX_LOADING = 6;
let currentLoad = 0;
let time = null;
//6 animations
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    let rnd = Math.floor(Math.random() * 7);
    localStorage.setItem("secret", rnd);
  }, []);
  const [story, setStory] = useState(false);
  function increaseLoading() {
    currentLoad++;
  }
  useEffect(() => {
    time = setInterval(() => {
      let percent = currentLoad / MAX_LOADING;
      setPercent(percent);
      if (percent === 1) {
        clearInterval(time);
        time = null;
      }
    }, 500);
  }, []);
  return (
    <>
      <Popup />
      {percent < 1 && <Loading percent={percent} />}
      <div className="flex flex-col mx-auto overflow-x-hidden">
        <div className="w-full flex justify-center">
          <Header
            onScrollStory={(sr) => {
              setStory(sr);
            }}
          />
        </div>
        <div className="w-full flex justify-center">
          <Social />
        </div>
        <Main increaseLoading={increaseLoading} showStory={story} />
        <Footer />
        <Tutorial />
      </div>
    </>
  );
}

export default App;

import styled from "styled-components/macro";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Secret from "./Secret";

const bossChallengeIdx = 1;
const arenaIdx = 2;
const adventureIdx = 3;
const battleFrontIdx = 4;

const PlayModeDetail = (mode) => {
  return (
    <div
      className={`expand-mobile fixed ${mode.bg} ${mode.classNameInModal} top-0 z-9999 h-full w-screen bg-red-500`}
    >
      {mode.mons?.map((el, idx) => (
        <div className={el.classNameInModal} key={idx} />
      ))}
      <img
        width="100%"
        height="100%"
        src={`/assets/close.svg`}
        alt="close button"
        className={`absolute top-5 right-5`}
        onClick={mode.onClose}
      />
      <div className="flex flex-col left-5 absolute justify-end bottom-5">
        <div className=" pb-3">
          <img
            src={mode.titleExpand}
            alt="monsterra title"
            width="100%"
            height="100%"
          />
        </div>{" "}
        <p className="text-white">{mode.description}</p>
      </div>
    </div>
  );
};

const listMode = [
  {
    bg: "bg-adventure_bg",
    classNameInModal: "bg-40",
    className: "w-[320px] h-[510px]",
    mons: [
      {
        bg: "bg-adventureMon1",
        className:
          "absolute bg-adventureMon1 top-[40px] left-[-190px] w-[454px] h-[498px] transform scale-50",
        classNameInModal:
          "absolute bg-adventureMon1 top-[220px] left-[-200px] w-[454px] h-[498px] transform scale-50",
      },
      {
        bg: "bg-adventureMon2",
        className:
          "absolute bg-adventureMon2 top-[-40px] left-[-50px] w-[430px] h-[748px] transform scale-[0.42]",
        classNameInModal:
          "absolute bg-adventureMon2 top-[165px] left-[-50px] w-[430px] h-[748px] transform scale-[0.42]",
      },
    ],
    position: "-150px 0",
    title: "/assets/play-mode/adventure.webp",
    titleExpand: "/assets/play-mode/adventure.webp",
    description:
      "Players lead their Mongen squads to exploit hidden reward tokens in the new lands using tactical strategies.",
  },
  {
    bg: "bg-boss_challange_bg",
    classNameInModal: "bg-55",
    className: "w-[320px] h-[510px]",
    position: "-300px 0",
    title: "/assets/play-mode/boss-challenge-title-expand.webp",
    titleExpand: "/assets/play-mode/boss-challenge-title-expand.webp",
    description:
      "Players lead their Mongen armies to conquer the surrounding lands occupied by dreadful beasts.",
  },
  {
    bg: "bg-battle_front_bg",
    classNameInModal: "bg-50",
    className: "w-[320px] h-[510px]",
    position: "-260px 0",
    mons: [
      {
        bg: "bg-battleFrontMon1",
        className:
          "absolute bg-battleFrontMon1 top-[-25px] left-[-390px] w-[792px] h-[796px] transform scale-[0.35]",
        classNameInModal:
          "absolute bg-battleFrontMon1 top-[220px] left-[-260px] w-[792px] h-[796px] transform scale-50",
      },
    ],
    title: "/assets/play-mode/battle-front-title.webp",
    titleExpand: "/assets/play-mode/battle-front-title.webp",
    description:
      "Players lead their Mongen armies to raid other players’ lands to “rob” the resources.",
  },
  {
    bg: "bg-arena_bg",
    className: "w-[320px] h-[510px]",
    classNameInModal: "bg-50",
    mons: [
      {
        bg: "bg-arenaMon1",
        className:
          "absolute bg-arenaMon1 top-[-35px] left-[-240px] w-[466px] h-[422px] transform scale-[0.5]",
        classNameInModal:
          "absolute bg-arenaMon1 top-[40px] left-[-170px] w-[466px] h-[422px] transform scale-50",
      },
      {
        bg: "bg-arenaMon2",
        className:
          "absolute bg-arenaMon2 top-[-155px] left-[-75px] w-[816px] h-[872px] transform scale-[0.45]",
        classNameInModal:
          "absolute bg-arenaMon2 top-0 left-[-10px] w-[816px] h-[872px] transform scale-[0.5]",
      },
    ],
    position: "-250px 0",
    title: "/assets/play-mode/arena.webp",
    titleExpand: "/assets/play-mode/arena.webp",
    description:
      "Players lead their Mongens squads to enter uncompromising head-to-head confrontations.",
  },
];

export const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

const DEFAULT_ADVENTURE_POSITION = "left-0";
const DEFAULT_BOSS_POSITION = "left-[19rem]";
const DEFAULT_BATTLE_POSITION = "md:left-0 xl:left-[38rem]";
const DEFAULT_ARENA_POSITION = "md:right-0 xl:right-[10px]";

export default function PlayModeAnimation(props) {
  const [leftCardFirst, setLeftCardFirst] = useState(
    DEFAULT_ADVENTURE_POSITION
  );

  const [leftCardTwo, setLeftCardTwo] = useState(DEFAULT_BOSS_POSITION);
  const [leftCardThree, setLeftCardThree] = useState(DEFAULT_BATTLE_POSITION);
  const [leftCardFour, setLeftCardFour] = useState(DEFAULT_ARENA_POSITION);

  const [currentMode, setCurrentMode] = useState();

  const [expanded, setExpanded] = useState(0);
  const ref = useRef(null);

  const resetPosition = () => {
    setLeftCardFirst(DEFAULT_ADVENTURE_POSITION);
    setLeftCardTwo(DEFAULT_BOSS_POSITION);
    setLeftCardThree(DEFAULT_BATTLE_POSITION);
    setLeftCardFour(DEFAULT_ARENA_POSITION);
  };

  const Mode = useCallback(({ list }) => {
    return list.map((mode, idx) => {
      return (
        <div className="w-1/2 min-w-[340px] p-2 flex justify-center">
          <Background
            key={idx}
            position={mode.position}
            className={`expand-mobile overflow-hidden relative w-72 h-515 border-4 rounded-3xl border-borderPlayMode ${mode.bg} ${mode.className} bg-w`}
            onClick={() => setCurrentMode(mode)}
          >
            {mode.mons?.map((el, idx) => (
              <div className={el.className} key={idx} />
            ))}
            <div className="ModeTitle flex bottom-0 left-0 flex-col absolute justify-end">
              <div className="bg-gradient-to-t from-black to-transparent w-full bg-contain z-10 p-4">
                <img
                  width="100%"
                  height="20px"
                  src={mode.title}
                  alt="mode title"
                  className="h-5 w-auto object-contain"
                />
                <p className="text-white mt-2">{mode.description}</p>
              </div>
            </div>

            <div className="ModeTitle1 flex flex-col w-[565px] bottom-[-50px] left-5 absolute justify-end">
              <div className=" pb-3">
                <img
                  src={mode.titleExpand}
                  alt="mode title"
                  width="100%"
                  height="100%"
                />
              </div>{" "}
              <p className="text-white">{mode.description}</p>
            </div>
          </Background>
        </div>
      );
    });
  }, []);

  const collapse = () => {
    setExpanded(0);
    sethoverText1("< Tap to expand >");
    sethoverText2("< Tap to expand >");
    sethoverText3("< Tap to expand >");
    sethoverText4("< Tap to expand >");
    resetPosition();
  };

  useOnClickOutside(ref, collapse);

  useEffect(() => {
    if (expanded === 0) {
      resetPosition();
    }
  }, [expanded]);

  const [hoverText1, sethoverText1] = useState("< Tap to expand >");
  const [hoverText2, sethoverText2] = useState("< Tap to expand >");
  const [hoverText3, sethoverText3] = useState("< Tap to expand >");
  const [hoverText4, sethoverText4] = useState("< Tap to expand >");

  const changeExpanded = (idx) => {
    if (expanded === idx) {
      if (idx === 1) sethoverText1("< Tap to expand >");
      if (idx === 2) sethoverText2("< Tap to expand >");
      if (idx === 3) sethoverText3("< Tap to expand >");
      if (idx === 4) sethoverText4("< Tap to expand >");
      collapse();
    } else {
      sethoverText1("< Tap to expand >");
      sethoverText2("< Tap to expand >");
      sethoverText3("< Tap to expand >");
      sethoverText4("< Tap to expand >");
      if (idx === 1) sethoverText1("< Tap to collapse >");
      if (idx === 2) sethoverText2("< Tap to collapse >");
      if (idx === 3) sethoverText3("< Tap to collapse >");
      if (idx === 4) sethoverText4("< Tap to collapse >");
      setExpanded(idx);
    }
  };
  return (
    <div className="bg-playMode md:block  bg-cover bg-center relative select-none overflow-hidden">
      <div
        className="h-16 w-full absolute top-0"
        style={{ backgroundImage: "url(/assets/ban.webp)" }}
      ></div>
      <div
        className="h-16 w-full absolute bottom-0"
        style={{ backgroundImage: "url(/assets/unban.webp)" }}
      ></div>
      <div className={`mt-32 mb-10`}>
        <p
          className="text-brown font-black text-3xl md:text-5xl pb-4 text-center"
          style={{ fontFamily: "CookieRun1" }}
        >
          FREE PLAY AND EARN
        </p>
        <p className="text-grey text-base px-[5px] md:px-0 md:text-2xl pb-14 text-center">
          Enjoy "Fun" And "Profit" With Economic Investment
        </p>
      </div>
      <div
        ref={ref}
        className="relative hidden hd:block bg-transparent bg-cover bg-center select-none h-634 flex items-center justify-center xl:w-[1200px] mx-auto"
      >
        <Adventure
          className={`expand ${
            expanded === adventureIdx ? "expanded" : ""
          } battle bg-adventure_bg relative h-full bg-no-repeat rounded-2xl overflow-hidden border-4 border-borderPlayMode cursor-pointer md:absolute top-0 ${leftCardFirst}`}
          onClick={() => {
            changeExpanded(adventureIdx);
            setLeftCardFirst(DEFAULT_ADVENTURE_POSITION);
            setLeftCardTwo("left-[850px]");
            setLeftCardThree("md:left-0 xl:left-[1160px]");
            setLeftCardFour("md:right-0 xl:right-[-550px]");
          }}
        >
          <div className="tapHoverAdventure hidden absolute top-0 w-full  flex-col justify-center items-center py-6 bg-gradient-to-b from-playMode1 to-transparent">
            <p className=" text-lg text-white">{hoverText3}</p>
          </div>

          <AdventureMon1 className="mons1 bg-adventureMon1 absolute z-20" />
          <AdventureMon2 className="mons2 bg-adventureMon2 absolute z-30" />
          <div className="bg-gradient-to-t from-black to-transparent absolute bottom-0 left-0 w-full bg-contain z-10"></div>
          <div className="ModeTitle flex bottom-6 left-5 flex-col absolute z-50 justify-end">
            <img
              width="151"
              height="21"
              src="/assets/play-mode/adventure.webp"
              alt="monsterra adventure"
            />
          </div>
          <div className="ModeTitle1 flex flex-col w-[565px] bottom-[-50px] left-5 absolute z-50 justify-end">
            {expanded === adventureIdx && <Secret id={3} cls="pb-28" />}
            <div className=" pb-3">
              <img
                src="/assets/play-mode/adventure.webp"
                alt="adventure"
                width="151"
                height="21"
              />
            </div>{" "}
            <p className="text-white">
              Players lead their Mongen squads to exploit hidden reward tokens
              in the new lands using tactical strategies.
            </p>
          </div>
        </Adventure>

        <Battle
          className={`expand ${
            expanded === bossChallengeIdx ? "expanded" : ""
          } battle bg-battle relative h-full bg-no-repeat rounded-2xl overflow-hidden border-4 border-borderPlayMode cursor-pointer md:absolute top-0 ${leftCardTwo}`}
          onClick={() => {
            changeExpanded(bossChallengeIdx);
            setLeftCardTwo("xl:left-[9rem]");
            setLeftCardFirst("md:left-[-300px] xl:left-[-160px]");
            setLeftCardThree("md:left-[1070px] xl:left-[990px]");
            setLeftCardFour("xl:right-[-370px]");
          }}
        >
          <div className="tapHoverBattle hidden absolute top-0 w-full  flex-col justify-center items-center py-6 bg-gradient-to-b from-playMode1 to-transparent">
            <p className=" text-lg text-white">{hoverText1}</p>
          </div>
          <Mons1 className="mons1 bg-battle-mons1 bg-no-repeat absolute z-20" />
          <Mons2 className="mons2 bg-battle-mons2 bg-no-repeat absolute z-10" />
          <Mons3 className="mons3 bg-battle-mons3 bg-no-repeat absolute z-30" />
          <div className="bg-gradient-to-t from-black to-transparent absolute bottom-0 left-0 w-full bg-contain z-10"></div>
          <div className="ModeTitle flex bottom-6 left-5 flex-col absolute z-50 justify-end">
            <img
              width="216"
              height="21"
              src="/assets/play-mode/boss-challenge-title-expand.webp"
              alt="boss challenge"
            />
          </div>
          <div className="text-bg-gradient ModeTitle1 flex flex-col w-[565px] bottom-[-50px] left-5 absolute z-50 justify-end">
            <div className=" pb-3">
              {expanded === bossChallengeIdx && <Secret id={4} cls="pb-36" />}
              <img
                width="216"
                height="21"
                src="/assets/play-mode/boss-challenge-title-expand.webp"
                alt="boss challenge"
              />
            </div>{" "}
            <p className="text-white">
              Players lead their Mongen armies to conquer the surrounding lands
              occupied by dreadful beasts.
            </p>
          </div>
        </Battle>

        <BattleFront
          className={`expand ${
            expanded === battleFrontIdx ? "expanded" : ""
          } battle bg-battle_front_bg relative h-full bg-no-repeat rounded-2xl overflow-hidden border-4 border-borderPlayMode cursor-pointer md:absolute md:top-[550px] xl:top-0 ${leftCardThree}`}
          onClick={() => {
            changeExpanded(battleFrontIdx);
            setLeftCardTwo("md:left-[19rem] xl:left-[-100px]");
            setLeftCardFirst("md:left-0 xl:left-[-410px]");
            setLeftCardThree("md:left-0 xl:left-[13rem]");
            setLeftCardFour("md:right-[-600px] xl:right-[-140px]");
          }}
        >
          <div className="tapHoverBattleFront hidden absolute top-0 w-full  flex-col justify-center items-center py-6 bg-gradient-to-b from-playMode1 to-transparent">
            <p className=" text-lg text-white">{hoverText4}</p>
          </div>
          <BattleFrontMon1 className="mons1 bg-battleFrontMon1 absolute z-20" />
          <div className="bg-gradient-to-t from-black to-transparent absolute bottom-0 left-0 w-full bg-contain z-10"></div>
          <div className="ModeTitle flex bottom-6 left-5 flex-col absolute z-50 justify-end">
            <img
              width="187"
              height="21"
              src="/assets/play-mode/battle-front-title.webp"
              alt="battle front"
            />
          </div>
          <div className="ModeTitle1 flex flex-col w-[565px] bottom-[-50px] left-5 absolute z-50 justify-end">
            <div className=" pb-3">
              {expanded === battleFrontIdx && <Secret id={5} cls="pb-14" />}
              <img
                width="187"
                height="21"
                src="/assets/play-mode/battle-front-title.webp"
                alt="battle front"
              />
            </div>{" "}
            <p className="text-white">
              Players lead their Mongen armies to raid other players’ lands to
              “rob” the resources
            </p>
          </div>
        </BattleFront>

        <Arena
          className={`expand ${
            expanded === arenaIdx ? "expanded" : ""
          } battle bg-arena_bg relative h-full bg-no-repeat rounded-2xl overflow-hidden border-4 border-borderPlayMode cursor-pointer md:absolute md:top-[550px] xl:top-0 ${leftCardFour}`}
          onClick={() => {
            changeExpanded(arenaIdx);
            setLeftCardFour(DEFAULT_ARENA_POSITION);
            setLeftCardThree("md:left-[-610px] xl:left-[60px]");
            setLeftCardTwo("md:left-[19rem] xl:left-[-250px]");
            setLeftCardFirst("md:left-0 xl:left-[-560px]");
          }}
        >
          <div className="tapHoverArenaMon hidden absolute top-0 w-full  flex-col justify-center items-center py-6 bg-gradient-to-b from-playMode1 to-transparent">
            <p className=" text-lg text-white">{hoverText2}</p>
          </div>
          <ArenaMon1 className="mons1 absolute z-20 bg-arenaMon1" />
          <ArenaMon2 className="mons2 absolute z-10 bg-arenaMon2" />
          <div className="bg-gradient-to-t from-black to-transparent absolute bottom-0 left-0 w-full  bg-contain z-10"></div>
          <div className="ModeTitle flex bottom-6 left-5 flex-col absolute z-50 justify-end">
            <img src="/assets/play-mode/arena.webp" alt="arena" />
          </div>
          <div className="ModeTitle1 flex flex-col w-[565px] bottom-[-50px] left-5 absolute z-50 justify-end">
            <div className=" pb-3">
              {expanded === arenaIdx && <Secret id={6} cls="pb-20" />}
              <img
                src="/assets/play-mode/arena.webp"
                alt="arena"
                width="85"
                height="21"
              />
            </div>{" "}
            <p className="text-white">
              Players lead their Mongens squads to enter uncompromising
              head-to-head confrontations.
            </p>
          </div>
        </Arena>
      </div>

      <div
        className={`flex justify-center flex-wrap items-center hd:hidden mb-10 pb-20 px-5`}
      >
        <Mode list={listMode} />
      </div>
      {/* {currentMode && (
        <PlayModeDetail
          {...currentMode}
          onClose={() => setCurrentMode(undefined)}
        />
      )} */}
    </div>
  );
}

const Battle = styled.div`
  width: 280px;
  height: 510px;
  background-position: -300px 0px;
  transition: all 0.5s ease-in-out;

  &:hover .tapHoverBattle {
    display: flex;
  }

  &.expanded {
    width: 823px;
    background-position: 0px 0px;

    & > .mons1 {
      top: 270px;
      left: 130px;
      transform: scale(1, 1);
    }

    & > .mons2 {
      top: 305px;
      left: 280px;
      transform: scale(1, 1);
    }

    & > .mons3 {
      top: 330px;
      left: 360px;
      transform: scale(1, 1);
    }
  }
`;
const Mons1 = styled.div`
  top: 300px;
  left: -100px;
  width: 163px;
  height: 165px;
  transform: scale(0.6, 0.6);
  transition: all 0.5s ease-in-out;
`;

const Mons2 = styled.div`
  top: 335px;
  left: -30px;
  width: 98px;
  height: 127px;
  transform: scale(0.7, 0.7);
  transition: all 0.5s ease-in-out;
`;
const Mons3 = styled.div`
  top: 330px;
  left: 20px;
  width: 183px;
  height: 170px;
  transform: scale(0.7, 0.7);
  transition: all 0.5s ease-in-out;
`;

//-----------
const Arena = styled.div`
  width: 280px;
  height: 510px;
  background-position: -280px 0px;
  transition: all 0.5s ease-in-out;

  &:hover .tapHoverArenaMon {
    display: flex;
  }

  &.expanded {
    width: 821px;
    background-position: 0px 0px;
    transition: all 0.5s ease-in-out;

    & > .mons1 {
      top: -70px;
      left: 30px;
      transform: scale(0.5, 0.5);
    }

    & > .mons2 {
      top: -145px;
      left: 210px;
      transform: scale(0.5, 0.5);
    }
  }
`;

const ArenaMon1 = styled.div`
  top: -40px;
  left: -180px;
  width: 466px;
  height: 422px;
  transform: scale(0.4, 0.4);
  transition: all 0.5s ease-in-out;
`;

const ArenaMon2 = styled.div`
  top: -145px;
  left: -135px;
  width: 816px;
  height: 872px;
  transform: scale(0.4, 0.4);
  //height: 356px;
  transition: all 0.5s ease-in-out;
`;

//----------------
const Adventure = styled.div`
  width: 280px;
  height: 510px;
  background-position: -230px 0px;
  transition: all 0.5s ease-in-out;

  &:hover .tapHoverAdventure {
    display: flex;
  }

  &.expanded {
    width: 821px;
    background-position: 0px 0px;
    transition: all 0.5s ease-in-out;

    & > .mons1 {
      top: 10px;
      left: -75px;
      transform: scale(0.5, 0.5);
      transition: all 0.5s ease-in-out;
    }

    & > .mons2 {
      top: -55px;
      left: 90px;
      transform: scale(0.5, 0.5);
      transition: all 0.5s ease-in-out;

      //transform: scale(1.15, 1.15);
    }
  }
`;

const AdventureMon1 = styled.div`
  top: 10px;
  left: -280px;
  width: 454px;
  height: 498px;
  transform: scale(0.5, 0.5);
  transition: all 0.5s ease-in-out;
`;

const AdventureMon2 = styled.div`
  top: -40px;
  left: -130px;
  width: 430px;
  height: 748px;
  transform: scale(0.42, 0.42);
  //height: 356px;
  transition: all 0.5s ease-in-out;
`;

//-----------
const BattleFront = styled.div`
  width: 280px;
  height: 510px;
  background-position: -260px 0px;
  transition: all 0.5s ease-in-out;

  &:hover .tapHoverBattleFront {
    display: flex;
  }

  &.expanded {
    width: 823px;
    background-position: 0px 0px;

    & > .mons1 {
      top: -90px;
      left: -200px;
      transform: scale(0.5, 0.5);
    }
  }
`;

const BattleFrontMon1 = styled.div`
  top: -25px;
  left: -390px;
  width: 792px;
  height: 796px;
  transform: scale(0.35, 0.35);
  transition: all 0.5s ease-in-out;
`;

const Background = styled.div`
  background-position: ${(props) => props.position};
`;

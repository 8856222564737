import { useState, useEffect } from "react";
import headerBoss from "styles/assets/header/boss.webp";
import headerBeast from "styles/assets/header/beast.webp";
import headerTectos from "styles/assets/header/tectos.webp";
import topSmoke from "styles/assets/header/top-smoke.webp";
export default function Banner(props) {
  const [scroll, setScroll] = useState(1);
  const [showTrailer, setShowTrailer] = useState(false);
  useEffect(() => {
    function onScroll() {
      let sc = 1 + window.scrollY / 800;
      if (sc > 2) {
        sc = 2;
      }
      setScroll(sc);
    }
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  const [comingsoon, setComingsoon] = useState("");
  const clickModal = (text) => {
    setComingsoon(text);
  };
  return (
    <div
      style={{ backgroundImage: `url(assets/smoke.webp)` }}
      className="bg-cover md:bg-center bg-49 flex flex-col items-center justify-center h-700 gap-4 relative"
    >
      {showTrailer && (
        <div className="w-screen h-screen fixed top-0 left-0 z-[60]">
          <img
            onClick={() => {
              setShowTrailer(false);
            }}
            src="assets/close.png"
            className="absolute right-6 top-2 w-14 h-14 cursor-pointer transform hover:scale-125 duration-75 "
          />
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/p0IP4Oajxxc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      )}
      <div className="absolute z-10 w-full h-[700px] left-0 top-0">
        <img
          width="100%"
          height="100%"
          alt="monsterra boss"
          src={headerBoss}
          className="transform scale-[230%] md:scale-100 md:w-[700px]  h-auto mx-auto block mt-36 md:mt-4"
        />
        <div className="w-full flex justify-center h-full items-center absolute top-0 left-0 mongen-appear">
          <div className="h-[460px] w-auto absolute hidden ipadpro:block header-mongen-anim">
            <img
              style={{
                transform: `translate(${20 * scroll}rem, ${3.5 * scroll}rem)`,
              }}
              src={headerBeast}
              className="f-full"
              alt="monsterra beasat"
            />
          </div>
          <div className="h-[300px] w-auto absolute  hidden ipadpro:block header-mongen-anim">
            <img
              style={{
                transform: `translate(-${18 * scroll}rem, ${7 * scroll}rem)`,
              }}
              src={headerTectos}
              className="h-full"
              alt="monsterra tectos"
            />
          </div>
        </div>
      </div>
      {/* smoke */}
      <div className="bg-header-smoke absolute top-0 left-0 w-full h-full z-30 bg-cover bg-center smoke-appear"></div>

      <img
        src={topSmoke}
        className="absolute bottom-0 w-full left-0 z-30"
        alt="smoke"
      />
      {comingsoon ? (
        <div
          id="success-popup"
          style={{ zIndex: 1000 }}
          className="fixed  top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50"
        >
          <div
            className={`${comingsoon} bg-cover md:h-[500px] h-1/3 md:w-[900px] w-4/5 relative bg-center rounded-md p-6 justify-center items-center flex`}
          >
            <div className="absolute bottom-10 md:bottom-20 left-0 flex justify-center items-center flex-col w-full">
              <div className="flex justify-center">
                <a
                  href="https://www.youtube.com/watch?v=GnYHd-RHI_g"
                  target="_blank"
                  className="text-[#4d2525] text-xl hover:opacity-60 duration-300 mr-3 md:mr-6"
                  rel="noreferrer"
                >
                  <img
                    src="assets/btn-teaser.png"
                    className="h-10 md:h-20"
                    alt="teaser"
                  />
                </a>
                <a
                  onClick={() => {
                    if (window.gtag) {
                      if (window.location.search) {
                        let query = new URLSearchParams(window.location.search);
                        console.log("event ", query.get("src"));
                        window.gtag("event", "play_now_clicked_programable", {
                          src: query.get("src"),
                        });
                      } else {
                        window.gtag(
                          "event",
                          "play_now_clicked_programable",
                          {}
                        );
                      }
                    }

                    window.open(
                      `https://play.monsterra.io${window.location.search}`
                    );
                  }}
                  target="_blank"
                  className="text-[#4d2525] text-xl hover:opacity-60 duration-300"
                  rel="noreferrer"
                >
                  <img
                    src="assets/btn-play-testnet.png"
                    className="h-10 md:h-20"
                    alt="play"
                  />
                </a>
              </div>
            </div>
            <div
              onClick={() => clickModal("")}
              className="absolute top-0 right-0 mr-3 mt-3 cursor-pointer react-button"
            >
              <img src="/assets/closeBlack.svg" alt="close button" />
            </div>
          </div>
        </div>
      ) : null}
      <div className="px-3 z-20 mt-44">
        <img
          width="100%"
          height="100%"
          className="pt-12 w-449"
          src="/assets/logo-full.webp"
          alt="Monsterra Logo Full"
        />
      </div>
      <div className="flex flex-col px-3 z-40 gap-4 justify-center mt-8">
        <div className="text-center">
          <img
            width="160"
            height="56"
            onClick={() => {
              setShowTrailer(true);
            }}
            className="cursor-pointer react-button h-14 mx-auto transform hover:scale-110 active:scale-100 duration-75"
            src="/assets/banner_trailer_button.webp"
            alt="Button Trailer"
          />
        </div>
        <div>
          <img
            onClick={() => {
              if (window.gtag) {
                if (window.location.search) {
                  let query = new URLSearchParams(window.location.search);
                  window.gtag("event", "play_now_clicked_programable", {
                    src: query.get("src"),
                  });
                } else {
                  window.gtag("event", "play_now_clicked_programable", {});
                }
              }
              window.open(`https://play.monsterra.io${window.location.search}`);
            }}
            className=" cursor-pointer react-button h-20 transform hover:scale-110 active:scale-100 duration-75"
            src="/assets/banner_play_now_button.webp"
            alt="Button Play Now"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
}

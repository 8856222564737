/** @format */

import { useState } from "react";
import img from "./aura.webp";
export default function Popup() {
  const [show, setShow] = useState(false);
  return (
    show && (
      <div className="fixed w-screen h-screen z-[100] bg-black bg-opacity-75">
        <div className="w-full h-full flex items-center justify-center">
          <div className="relative">
            <img
              onClick={() => {
                setShow(false);
              }}
              src="assets/close.png"
              className="absolute top-[0px] right-[0px] w-14 transform hover:scale-110 duration-100 cursor-pointer"
            />
            <a href="https://twitter.com/Monsterra_P2E" target="_blank">
              <img src={img} style={{ maxHeight: "80vh", maxWidth: "80vw" }} />
            </a>
          </div>
        </div>
      </div>
    )
  );
}

/** @format */

import { useRef, useState } from "react";
import roadmapBorder from "styles/assets/roadmap-border.png";
import Slider from "react-slick";

export default function Roadmap(props) {
  const [tab, setTab] = useState(3);
  let navRef = useRef({});
  let mainRef = useRef({});
  const Timeline = ({ title, timelines, collapse, onClick, hideTitle }) => {
    return (
      <div
        className="flex flex-col mt-4 mr-3 transition duration-300 cursor-pointer"
        onClick={onClick}
      >
        {!hideTitle && (
          <div className="bg-lightYellow rounded-2xl px-3 py-2 mt-3">
            <p className="text-3xl font-bold text-brown">{title}</p>
          </div>
        )}
        <div
          className={`flex  transition duration-300 w-full justify-center ${
            collapse ? "md:flex-col" : "md:flex-row"
          } flex-col  mt-3`}
        >
          <div
            className={`flex  transition duration-300 w-full gap-2 justify-center ${
              collapse ? "md:flex-col" : "md:flex-row"
            } flex-col  mt-3`}
          >
            {timelines.map((timeline, index) => {
              return (
                <div className="w-full md:w-[250px] mt-5 md:mt-2">
                  <div
                    className={` transition duration-300 rounded-3xl border-4 overflow-hidden ${
                      timeline.isCurrent
                        ? "border-lightGreenLight bg-lightGreen"
                        : "border-lightBrown bg-grey1"
                    }`}
                  >
                    <div className="flex justify-between px-3 py-3">
                      <p
                        className={`${
                          collapse ? "text-xl" : "text-4xl"
                        } font-black  transition duration-300  ${
                          timeline.isCurrent
                            ? "text-heavyGreen"
                            : "text-blackBlue"
                        }`}
                      >
                        {timeline.time}
                      </p>
                      {timeline.isCurrent ? (
                        <img
                          width="24"
                          height="24"
                          src="/assets/tick.svg"
                          alt="roadmap"
                          className={`${collapse && "h-6"}`}
                        />
                      ) : null}
                    </div>
                    <div
                      className={` px-2 py-3  transition duration-300  md:h-[370px] timeline overflow-auto ${
                        collapse && "hidden"
                      } ${
                        timeline.isCurrent
                          ? ` bg-white text-heavyGreen`
                          : "bg-white"
                      } `}
                    >
                      {timeline.content.map((e, pIndex) => {
                        return (
                          <p
                            className={`text-sm ${
                              pIndex < timeline.done && "line-through"
                            }`}
                          >
                            {e}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const time = ["2021", "2022", "2023", "2024"];
  const settings = {
    customPaging: function (i) {
      return <p className="cursor-pointer hover:opacity-75">{time[i]}</p>;
    },
    fade: true,
    nextArrow: <div></div>,
    prevArrow: <div></div>,
    dots: true,
    dotsClass: "roadmap-nav",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
  };
  return (
    <div className="relative">
      <div
        className="h-16 w-[500px] absolute top-0"
        style={{ backgroundImage: "url(/assets/ban.webp)" }}
      ></div>
      <div className="hidden xl:flex pb-36 px-10 bg-roadmap relative bg-cover bg-center  flex-col items-center justify-start select-none">
        <div className="max-w-1920 w-[90%] mx-auto relative text-left">
          <img src={roadmapBorder} className="absolute -left-20 top-28 w-96" />
          <img
            src={roadmapBorder}
            className="absolute -right-20 top-28 w-96"
            style={{ transform: "scale(-1, 1)" }}
          />
          <img
            src={roadmapBorder}
            className="absolute -right-20 -bottom-20 w-96"
            style={{ transform: "scale(-1, -1)" }}
          />
          <img
            src={roadmapBorder}
            className="absolute -left-20 -bottom-20 w-96"
            style={{ transform: "scale(1, -1)" }}
          />
          {/* <img src={roadmapBorder} className="absolute left-20 top-28 w-96" /> */}
          <p
            className="text-brown font-black text-5xl pt-32 pb-4 text-center"
            style={{ fontFamily: "CookieRun1" }}
          >
            FOLLOWING ROADMAP
          </p>
          <p className="text-grey text-2xl pb-14 w-[700px] mx-auto text-center">
            Our team fully focus on this timeline details regarding product &
            business development
          </p>
          <Slider {...settings} initialSlide={3} ref={mainRef}>
            <div>
              <Timeline
                hideTitle
                onClick={() => {
                  setTab(0);
                }}
                // collapse={tab !== 0}
                title="2021"
                timelines={[
                  {
                    time: "Q3",
                    isCurrent: false,
                    done: 3,
                    content: ["Concept design", "Whitepaper", "Pitch deck"],
                  },
                  {
                    time: "Q4",
                    isCurrent: false,
                    done: 7,
                    content: [
                      "- Game Conceptualization",
                      "- White Paper + Pitch Deck Release",
                      "- Website Launching",
                      "- PR Collaborations ",
                      "- Partnership Expansion",
                      "- Community Growth ",
                      "- Game Trailer Release",
                    ],
                  },
                ]}
              />
            </div>
            <div>
              <Timeline
                hideTitle
                onClick={() => {
                  setTab(1);
                }}
                title="2022"
                // collapse={tab === 0}
                timelines={[
                  {
                    time: "Q1",
                    isCurrent: false,
                    done: 8,
                    content: [
                      "- Mongens Contest ",
                      "- Internal Game Testnet ",
                      "- INO ",
                      "- Giveaway/ Bounties",
                      "- Game Guild Collaboration",
                      "- Public Testnet Campaign",
                      "- NFT Marketplace Released",
                      "- Portal Game for NFTs",
                    ],
                  },
                  {
                    time: "Q2",
                    isCurrent: false,
                    done: 5,
                    content: [
                      "- Portal Games for NFTs Released",
                      "- Testnet Farming Released",
                      "- Testnet Adventure Released",
                      "- Partership Expansion",
                      "- Community Events",
                    ],
                  },
                  {
                    time: "Q3",
                    isCurrent: false,
                    done: 10,
                    content: [
                      "- Testnet Battlefront release",
                      "- IDO (MSTR)",
                      "- Pancakeswap & MEXC Listing",
                      "- Mainnet launching game v1: Adventure",
                      "- Mid-autumn Event",
                      "- DAO treasury",
                      "- Game v2: Battlefront",
                      "- Land Decoration",
                      "- September Star on BNB Chain ",
                      "- Top most searched Game (24h)",
                    ],
                  },
                  {
                    time: "Q4",
                    isCurrent: false,
                    done: 10,
                    content: [
                      "- Game V3: Boss Challenge",
                      "- Halloween Event",
                      "- Mobile version: Android",
                      "- Celest & Chaos Races",
                      "- Guild: Clan manager",
                      "- New Dex Listing",
                      "- Dual chain: BNB and AVAX",
                      "- Portal game v3",
                      "- WorldCup Fiesta x BNB Chain ",
                      "- Seasonal Events x Avalanche ",
                    ],
                  },
                ]}
              />
            </div>
            <div>
              <Timeline
                hideTitle
                onClick={() => {
                  setTab(2);
                }}
                title="2023"
                // collapse={tab === 0}
                timelines={[
                  {
                    time: "Q1",
                    isCurrent: false,
                    done: 12,
                    content: [
                      "- Auction: Bid to earn",
                      "- Multichain: BNB, OKX and AVAX",
                      "- Mobile version: iOS",
                      "- Legendary Mongens",
                      "- Legend Isla: The origin of a legend",
                      "- Boss Challenge Event Series",
                      "- Battle: Abyss Tower (new mode)",
                      "- Booming series of events x OKX ",
                      "- Gaming events x AVAX",
                    ],
                  },
                  {
                    time: "Q2",
                    done: 9,
                    isCurrent: false,
                    content: [
                      "- Runeword",
                      "- Battle: Arena",
                      "- Monswallet: GameFi wallet",
                      "- MonsGPT: AI Assistant",
                      "- Boss Challenge Event Series",
                      "- Multi language",
                      "- New DEX & CEX listing",
                      "- New Chain Expansion",
                    ],
                  },
                  {
                    time: "Q3",
                    isCurrent: false,
                    done: 8,
                    content: [
                      "- Guild: Scholarship",
                      "- Guild: Clan Tournament",
                      "- MonsHub: Hybrid Gaming Hub",
                      "- MonsTV: In-game MonsTV",
                      "- LayerZero Integration",
                      "- New Chain Expansion",
                      "- Boss Challenge Event Series",
                      "- Other Wallet Collaboration & Integration",
                    ],
                  },
                  {
                    time: "Q4",
                    isCurrent: false,
                    done: 10,
                    content: [
                      "- Monsterra Universe expansion: ",
                      "- Design Monsterverse",
                      "- Design new earning mechanics for MSTR holders in Monsterverse",
                      "- Cosmos NFT Sales",
                      "- Xmas & New Year Campaign on Social & Community",
                      "- Halotrade Listing",
                      "- Cosmos Launch",
                      "- KOLs & Ambassadors Program",
                      "- AMAs & Co-host Events with other Ecosystem Partners",
                    ],
                  },
                ]}
              />
            </div>
            <div>
              <Timeline
                hideTitle
                onClick={() => {
                  setTab(3);
                }}
                title="2024"
                // collapse={tab === 0}
                timelines={[
                  {
                    time: "Q1",
                    isCurrent: true,
                    content: [
                      "- New game on a new Planet: Pocket Monster",
                      "- zkSync integration Promote earnings mechanics Grow X & TG for new game",
                      "- Research Layer3 (A gaming Interchain layer)",
                      "- MonsHub IOS launching Voting camp",
                      "- Lunar New Year, Valentine, Easter: In-game Activities",
                      "- New Partners Connection AMAs & Panels",
                      "- ZkSync MKT support Social, highlight, community",
                      "- Digitals Ads (Monsterra, MonsHub, Pocket Monsters, MonsFarm, Monster Defense, Infinity Wars)",
                      "- Ambassadors Program",
                      "- Lifetime Referral Campaign: Invite & Earn for all users",
                    ],
                  },
                  {
                    time: "Q2",
                    isCurrent: false,
                    content: [
                      "- New game on a new Planet: Monsfarm",
                      "- X1 intergration  Promote earnings mechanics Grow X & TG for new game",
                      "- Research Layer3 (A gaming Interchain layer)",
                      "- MonsHub: New minigame",
                      "- Extra CEXs, DEXs Listing",
                      "- X1 Partners Expansion  AMAs, Panels with partners",
                      "- X1 MKT support Social, highlight, community",
                      "- Digitals Ads (Monsterra, MonsHub, Pocket Monsters, MonsFarm, Monster Defense, Infinity Wars)",
                      "- Ambassadors Program",
                      "- Lifetime Referral Campaign: Invite & Earn for all users",
                    ],
                  },
                  {
                    time: "Q3",
                    isCurrent: false,
                    content: [
                      "- New game on new Planet: Monster Defense",
                      "- Clan Stronghold Grow X & TG for new game",
                      "- Clan World map",
                      "- Clan World war",
                      "- Develop layer3",
                      "- 2nd Birthday  Celebration & Tournament ",
                      "- Social & Community Fest",
                      "- Digitals Ads (Monsterra, MonsHub, Pocket Monsters, MonsFarm, Monster Defense, Infinity Wars)",
                      "- Loyalty Campaign",
                      "- Lifetime Referral Campaign: Invite & Earn for all users",
                    ],
                  },
                  {
                    time: "Q4",
                    isCurrent: false,
                    content: [
                      "- Infinity War: Interplanetary war on Layer3",
                      "- Monsterverse Cup",
                      "- Birthday Recap x Mass PRs ",
                      "- Xmas & Year End Party with all users & partners",
                      "- Digitals Ads (Monsterra, MonsHub, Pocket Monsters, MonsFarm, Monster Defense, Infinity Wars)",
                      "- Lifetime Referral Campaign: Invite & Earn for all users",
                    ],
                  },
                ]}
              />
            </div>
          </Slider>
        </div>
      </div>
      <div className="bg-playMode bg-cover bg-center flex items-center justify-center xl:hidden relative">
        <div className="absolute top-20 px-3 w-full ">
          <img
            width="100%"
            height="100%"
            className="w-full"
            src={"/assets/roadmap/top.png"}
            alt={"Tokenomic Paper"}
          />
        </div>
        <div className="absolute bottom-7 px-3 w-full">
          <img
            width="100%"
            height="100%"
            className="w-full"
            src={"/assets/roadmap/bottom.png"}
            alt={"Tokenomic Paper"}
          />
        </div>
        <div
          className="h-16 w-full absolute top-0"
          style={{ backgroundImage: "url(/assets/ban.webp)" }}
        ></div>
        <div className="z-10 text-center">
          <p
            className="text-brown font-black text-[28px] pt-32 pb-4 text-center"
            style={{ fontFamily: "CookieRun1" }}
          >
            FOLLOWING ROADMAP
          </p>
          <p className="text-grey text-base pb-14 xl:w-600 px-[10vw] text-center">
            Our team fully focus on this timeline details regarding product &
            business development
          </p>
          <div className="flex flex-col px-[10vw] mb-20 text-left">
            <Timeline
              onClick={() => {
                setTab(0);
              }}
              title="2021"
              // collapse={tab !== 0}
              timelines={[
                {
                  time: "Q3",
                  isCurrent: false,
                  done: 3,
                  content: ["Concept design", "Whitepaper", "Pitch deck"],
                },
                {
                  time: "Q4",
                  isCurrent: false,
                  done: 7,
                  content: [
                    "- Game Conceptualization",
                    "- White Paper + Pitch Deck Release",
                    "- Website Launching",
                    "- PR Collaborations ",
                    "- Partnership Expansion",
                    "- Community Growth ",
                    "- Game Trailer Release",
                  ],
                },
              ]}
            />
            <Timeline
              onClick={() => {
                setTab(1);
              }}
              title="2022"
              // collapse={tab !== 1}
              timelines={[
                {
                  time: "Q1",
                  isCurrent: false,
                  done: 8,
                  content: [
                    "- Mongens Contest ",
                    "- Internal Game Testnet ",
                    "- INO ",
                    "- Giveaway/ Bounties",
                    "- Game Guild Collaboration",
                    "- Public Testnet Campaign",
                    "- NFT Marketplace Released",
                    "- Portal Game for NFTs",
                  ],
                },
                {
                  time: "Q2",
                  isCurrent: false,
                  done: 5,
                  content: [
                    "- Portal Games for NFTs Released",
                    "- Testnet Farming Released",
                    "- Testnet Adventure Released",
                    "- Partership Expansion",
                    "- Community Events",
                  ],
                },
                {
                  time: "Q3",
                  isCurrent: false,
                  done: 11,
                  content: [
                    "- Game v3: Boss Challenge",
                    "- Halloween Event",
                    "- Mobile version",
                    "- Celest & Chaos races",
                    "- Guild portal: Scholarship",
                    "- Guild portal: Clan Management",
                    "- Game v3: Arena",
                    "- MonsTV: Watch to earn",
                    "- New DEX & CEX listing",
                    "- Dual-chain",
                    "- Portal game v3",
                  ],
                },
                {
                  time: "Q4",
                  isCurrent: false,
                  done: 10,
                  content: [
                    "- Game V3: Boss Challenge",
                    "- Halloween Event",
                    "- Mobile version: Android",
                    "- Celest & Chaos Races",
                    "- Guild: Clan manager",
                    "- New Dex Listing",
                    "- Dual chain: BNB and AVAX",
                    "- Portal game v3",
                    "- WorldCup Fiesta x BNB Chain ",
                    "- Seasonal Events x Avalanche ",
                  ],
                },
              ]}
            />
            <Timeline
              onClick={() => {
                setTab(2);
              }}
              title="2023"
              // collapse={tab !== 1}
              timelines={[
                {
                  time: "Q1",
                  isCurrent: false,
                  done: 12,
                  content: [
                    "- Auction: Bid to earn",
                    "- Multichain: BNB, OKX and AVAX",
                    "- Mobile version: iOS",
                    "- Legendary Mongens",
                    "- Legend Isla: The origin of a legend",
                    "- Boss Challenge Event Series",
                    "- Battle: Abyss Tower (new mode)",
                    "- Booming series of events x OKX ",
                    "- Gaming events x AVAX",
                  ],
                },
                {
                  done: 9,
                  time: "Q2",
                  isCurrent: false,
                  content: [
                    "- Runeword",
                    "- Battle: Arena",
                    "- Monswallet: GameFi wallet",
                    "- MonsGPT: AI Assistant",
                    "- Boss Challenge Event Series",
                    "- Multi language",
                    "- New DEX & CEX listing",
                    "- New Chain Expansion",
                  ],
                },
                {
                  time: "Q3",
                  isCurrent: false,
                  done: 8,
                  content: [
                    "- Guild: Scholarship",
                    "- Guild: Clan Tournament",
                    "- MonsHub: Hybrid Gaming Hub",
                    "- MonsTV: In-game MonsTV",
                    "- LayerZero Integration",
                    "- New Chain Expansion",
                    "- Boss Challenge Event Series",
                    "- Other Wallet Collaboration & Integration",
                  ],
                },
                {
                  time: "Q4",
                  isCurrent: false,
                  done: 10,
                  content: [
                    "- Monsterra Universe expansion: ",
                    "- Design Monsterverse",
                    "- Design new earning mechanics for MSTR holders in Monsterverse",
                    "- Cosmos NFT Sales",
                    "- Xmas & New Year Campaign on Social & Community",
                    "- Halotrade Listing",
                    "- Cosmos Launch",
                    "- KOLs & Ambassadors Program",
                    "- AMAs & Co-host Events with other Ecosystem Partners",
                  ],
                },
              ]}
            />
            <Timeline
              onClick={() => {
                setTab(2);
              }}
              title="2024"
              // collapse={tab !== 1}
              timelines={[
                {
                  time: "Q1",
                  isCurrent: true,
                  content: [
                    "- New game on a new Planet: Pocket Monster",
                    "- zkSync integration Promote earnings mechanics Grow X & TG for new game",
                    "- Research Layer3 (A gaming Interchain layer)",
                    "- MonsHub IOS launching Voting camp",
                    "- Lunar New Year, Valentine, Easter: In-game Activities",
                    "- New Partners Connection AMAs & Panels",
                    "- ZkSync MKT support Social, highlight, community",
                    "- Digitals Ads (Monsterra, MonsHub, Pocket Monsters, MonsFarm, Monster Defense, Infinity Wars)",
                    "- Ambassadors Program",
                    "- Lifetime Referral Campaign: Invite & Earn for all users",
                  ],
                },
                {
                  time: "Q2",
                  isCurrent: false,
                  content: [
                    "- New game on a new Planet: Monsfarm",
                    "- X1 intergration  Promote earnings mechanics Grow X & TG for new game",
                    "- Research Layer3 (A gaming Interchain layer)",
                    "- MonsHub: New minigame",
                    "- Extra CEXs, DEXs Listing",
                    "- X1 Partners Expansion  AMAs, Panels with partners",
                    "- X1 MKT support Social, highlight, community",
                    "- Digitals Ads (Monsterra, MonsHub, Pocket Monsters, MonsFarm, Monster Defense, Infinity Wars)",
                    "- Ambassadors Program",
                    "- Lifetime Referral Campaign: Invite & Earn for all users",
                  ],
                },
                {
                  time: "Q3",
                  isCurrent: false,
                  content: [
                    "- New game on new Planet: Monster Defense",
                    "- Clan Stronghold Grow X & TG for new game",
                    "- Clan World map",
                    "- Clan World war",
                    "- Develop layer3",
                    "- 2nd Birthday  Celebration & Tournament ",
                    "- Social & Community Fest",
                    "- Digitals Ads (Monsterra, MonsHub, Pocket Monsters, MonsFarm, Monster Defense, Infinity Wars)",
                    "- Loyalty Campaign",
                    "- Lifetime Referral Campaign: Invite & Earn for all users",
                  ],
                },
                {
                  time: "Q4",
                  isCurrent: false,
                  content: [
                    "- Infinity War: Interplanetary war on Layer3",
                    "- Monsterverse Cup",
                    "- Birthday Recap x Mass PRs ",
                    "- Xmas & Year End Party with all users & partners",
                    "- Digitals Ads (Monsterra, MonsHub, Pocket Monsters, MonsFarm, Monster Defense, Infinity Wars)",
                    "- Lifetime Referral Campaign: Invite & Earn for all users",
                  ],
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

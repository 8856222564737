export default function TeamMember(props) {
  const listAdvisor = [
    {
      name: "Thi Truong",
      width: "305px",
      position: "Executive Advisor",
      image: "/assets/member/thitruong.webp",
      description:
        "Thi is the Founder and CEO of PolkaFoundry, Red Kite launchpad, and Icetea Labs, incubator of Faraland, Bunicorn and several other successful projects.",
    },
    // {
    //   hidden: true,
    //   name: "Joseph Young",
    //   width: "0px",
    //   position: "Advisor",
    //   image: "/assets/member/Changhan Kim.webp",
    //   description: "",
    // },
  ];

  const list = [
    {
      name: "Victor Thang",
      width: "380px",
      position: "Product manager",
      image: "/assets/member/thangmai.webp",
      description:
        "Thang owns 13 years of experience in the software and game development industry. He won the 3rd prize in the Mobilabs 2008 competition held by FPT for the mobile application.",
    },
    {
      name: "Marc Nguyen",
      width: "275px",
      position: "Lead Game designer",
      image: "/assets/member/trungnguyen.webp",
      description:
        "Marc has been working in the game industry for 11 years in various positions for both outsourcing and product environment. He also devoted himself to developing processes for several projects from hyper-casual to triple-A.",
    },
    {
      name: "Iris",
      width: "275px",
      position: "CMO",
      image: "/assets/member/anhle.webp",
      description:
        "5-year solid track record as Marketing Manager & Advisor in charge of the plan, project management, community building, social media boosting, and growth-hack strategies in Blockchain, NFTs, Gaming, Iris is appointed to be the project's CMO.",
    },
    {
      name: "Tony Do",
      width: "205px",
      position: "Lead Game Backend",
      image: "/assets/member/tungdo.webp",
      description:
        "Tony is recognized as a devoted Senior Backend Developer with 6 years of experience in heavy traffic systems. ",
    },
    {
      name: "Alex Nguyen",
      width: "235px",
      position: "Lead Unity Developer",
      image: "/assets/member/truongnguyen.webp",
      description:
        "Owning 6 years of working experience in a variety of game genres such as action, adventure, TD, shooting, Alex is a dedicated Senior Unity Developer to the team.",
    },
    {
      name: "Dia",
      width: "215px",
      position: "Art Leader",
      image: "/assets/member/thanhnguyen.webp",
      description:
        "Dia is an experienced Illustrator / Concept Artist in the game industry with more than 7 years of employing time",
    },
    {
      name: "Tobey Luu",
      width: "255px",
      position: "Lead Animator and UI/UX",
      image: "/assets/member/linhvu.webp",
      description:
        "Tobey is a Tech Animation Leader with 6 years of 2D Animation experience in the game industry. Involving numerous projects during these past few years made him knowledgeable, skillful, and competent.",
    },
    {
      name: "Tuan Dang",
      width: "320px",
      position: "Blockchain Technical Lead",
      image: "/assets/member/tuandang.webp",
      description:
        "With more than 10 years of hands-on experience in various IT development areas from the database, backend, mobile app, Cloud computing, AI as well as 4 years of experience in blockchain technologies from Permissioned blockchain (E.g. Hyperledger Fabric, Corda) to Permissionless blockchain (E.g. Ethereum, Neo, Polkadot), Tuan plays an integral part in the team.",
    },
    {
      name: "Andy Nguyen",
      width: "230px",
      position: "Lead Tester and Scrum Master",
      image: "/assets/member/dungnguyen.webp",
      description:
        "Andy has a solid understanding of the Game industry as he has been diving in the field for more than 7 years in the Quality Assurance position.",
    },
    {
      name: "Noah",
      width: "220px",
      position: "Artist",
      image: "/assets/member/thuannguyen.webp",
      description:
        "Being in the Concept Artist / Illustrator position in the game industry for more than 5 years, Noah is proficient at his expertise.",
    },
    {
      name: "Nanaa",
      width: "250px",
      position: "Artist",
      image: "/assets/member/ngando.webp",
      description:
        "Nanaa is a creative artist with 5 years of experience in both outsourcing and product environment. She has participated in a variety of world-class game projects from Korea, Japan, etc.",
    },
  ];

  const listCoreTeam = [
    {
      name: "Victor Thang",
      width: "300px",
      position: "Product manager",
      image: "/assets/member/thangmai.webp",
      description:
        "Thang owns 13 years of experience in the software and game development industry. He won the 3rd prize in the Mobilabs 2008 competition held by FPT for the mobile application.",
    },
    {
      name: "Marc Nguyen",
      width: "320px",
      position: "Lead Game designer",
      image: "/assets/member/trungnguyen.webp",
      description:
        "Marc has been working in the game industry for 11 years in various positions for both outsourcing and product environment. He also devoted himself to developing processes for several projects from hyper-casual to triple-A.",
    },
    {
      name: "Iris",
      width: "345px",
      position: "CMO",
      image: "/assets/member/anhle.webp",
      description:
        "5-year solid track record as Marketing Manager & Advisor in charge of the plan, project management, community building, social media boosting, and growth-hack strategies in Blockchain, NFTs, Gaming, Iris is appointed to be the project's CMO.",
    },
  ];

  const listProduction2 = [
    {
      name: "Tony Do",
      width: "265px",
      position: "Lead Game Backend",
      image: "/assets/member/tungdo.webp",
      description:
        "Tony is recognized as a devoted Senior Backend Developer with 6 years of experience in heavy traffic systems. ",
    },
    {
      name: "Alex Nguyen",
      width: "295px",
      position: "Lead Unity Developer",
      image: "/assets/member/truongnguyen.webp",
      description:
        "Owning 6 years of working experience in a variety of game genres such as action, adventure, TD, shooting, Alex is a dedicated Senior Unity Developer to the team.",
    },
    {
      name: "Dia",
      width: "275px",
      position: "Art Leader",
      image: "/assets/member/thanhnguyen.webp",
      description:
        "Dia is an experienced Illustrator / Concept Artist in the game industry with more than 7 years of employing time",
    },
    {
      name: "Tobey Luu",
      width: "315px",
      position: "Lead Animator and UI/UX",
      image: "/assets/member/linhvu.webp",
      description:
        "Tobey is a Tech Animation Leader with 6 years of 2D Animation experience in the game industry. Involving numerous projects during these past few years made him knowledgeable, skillful, and competent.",
    },
  ];
  const listProduction3 = [
    {
      name: "Tuan Dang",
      width: "380px",
      position: "Blockchain Technical Lead",
      image: "/assets/member/tuandang.webp",
      description:
        "With more than 10 years of hands-on experience in various IT development areas from the database, backend, mobile app, Cloud computing, AI as well as 4 years of experience in blockchain technologies from Permissioned blockchain (E.g. Hyperledger Fabric, Corda) to Permissionless blockchain (E.g. Ethereum, Neo, Polkadot), Tuan plays an integral part in the team.",
    },
    {
      name: "Andy Nguyen",
      width: "280px",
      position: "Lead Tester and Scrum Master",
      image: "/assets/member/dungnguyen.webp",
      description:
        "Andy has a solid understanding of the Game industry as he has been diving in the field for more than 7 years in the Quality Assurance position.",
    },
    {
      name: "Noah",
      width: "280px",
      position: "Artist",
      image: "/assets/member/thuannguyen.webp",
      description:
        "Being in the Concept Artist / Illustrator position in the game industry for more than 5 years, Noah is proficient at his expertise.",
    },
    {
      name: "Nanaa",
      width: "300px",
      position: "Artist",
      image: "/assets/member/ngando.webp",
      description:
        "Nanaa is a creative artist with 5 years of experience in both outsourcing and product environment. She has participated in a variety of world-class game projects from Korea, Japan, etc.",
    },
  ];

  const ListMember = ({ listMember }) => {
    return (
      <div className="flex flex-wrap items-start justify-center gap-3 md:mt-[30px] md:gap-16 pb-4">
        {listMember.map((member) => {
          return (
            <div className="flex flex-col items-center">
              <div className="tooltip relative w-32">
                <img
                  className="absolute rounded-full"
                  src={member.image}
                  style={{ width: "96px", top: "17px", left: "16px" }}
                  alt="monsterra team"
                />
                <img
                  src="/assets/cover.webp"
                  alt="monsterra team"
                  width="322"
                  height="306"
                />
                {!member.hidden && (
                  <div
                    className="tooltiptext md:block hidden"
                    style={{ width: member.width }}
                  >
                    <div>
                      <img
                        width="800"
                        height="578"
                        src="/assets/member/tooltip.webp"
                        alt="monsterra team"
                      />
                    </div>
                    <div className="flex flex-col absolute top-0 text-left mt-8 mx-5">
                      <p className="text-base font-bold mb-3">{member.name}</p>
                      <p className="text-sm">{member.description}</p>
                    </div>
                  </div>
                )}
              </div>
              <p className="text-xl pt-4">{member.name}</p>
              <p className="text-base text-grey w-40 text-center">
                {member.position}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className=" bg-playMode bg-cover bg-center select-none relative">
      <div
        className="h-16 w-full absolute top-0"
        style={{ backgroundImage: "url(/assets/ban.webp)" }}
      ></div>
      <div
        className="h-16 w-full absolute bottom-0"
        style={{ backgroundImage: "url(/assets/unban.webp)" }}
      ></div>
      <div className="flex flex-col justify-center items-center text-center h-full">
        <p
          className="text-brown4 font-black text-5xl pb-4 pt-32 md:flex hidden"
          style={{ fontFamily: "CookieRun1" }}
        >
          OUR SOLID TEAM MEMBERS
        </p>
        <p
          className="text-brown font-black text-3xl pt-32 md:hidden flex "
          style={{ fontFamily: "CookieRun1" }}
        >
          OUR SOLID
        </p>
        <p
          className="text-brown font-black text-3xl pb-4 md:hidden flex"
          style={{ fontFamily: "CookieRun1" }}
        >
          TEAM MEMBERS
        </p>
        <p className="text-grey text-base md:text-2xl pb-16 w-full lg:w-[850px] text-center px-4">
          We are proud to be a dedicated team whose each member masters in
          his/her expertise and always enthusiatic to contribute developing
          Monsterra as the best NFT games in the metaverse.
        </p>
        <p className="text-2xl text-heavyTint font-black pb-6">- Advisors -</p>
        <ListMember listMember={listAdvisor} />
        {/* <p className="text-2xl text-heavyTint font-black pb-6">- Core Team -</p> */}
        <p className="text-2xl text-heavyTint font-black pt-2 pb-6">
          - Core Team -
        </p>
        <div className="md:block hidden">
          <ListMember listMember={listCoreTeam} />
          <ListMember listMember={listProduction2} />
          <ListMember listMember={listProduction3} />
        </div>
        <div className="flex md:hidden">
          <ListMember listMember={list} />
        </div>
        <div className="h-32" />
      </div>
    </div>
  );
}

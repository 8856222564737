import { useState, useEffect } from "react";

export default function Tutorial() {
  // return null;
  const [show, setShow] = useState(true);
  return (
    <>
      <Sharing></Sharing>
      <div className="fixed bottom-0 left-4 hd:left-10 z-[99] hidden md:block transform w-[200px] hd:w-[300px]">
        <div className="relative">
          <img
            className={`cursor-pointer transform origin-bottom-left ${
              show ? "scale-100" : "scale-75"
            } duration-300`}
            src="/assets/tutorial-collapse.png"
            onClick={() => {
              setShow(true);
            }}
          />
          {show && (
            <div
              className="absolute bottom-20 hd:bottom-44 left-24 hd:left-48 w-96 h-44 bg-contain bg-no-repeat px-6 py-4 transform scale-75 hd:scale-100"
              style={{ backgroundImage: `url(assets/tutorial-text.png)` }}
            >
              <div className="relative">
                <div className="text-center">
                  <div className="flex gap-1">
                    <div>
                      <p className="text-red-600 font-bold text-md mt-4">
                        One App for all Monsterra
                      </p>
                      <p className="mt-1">
                        Download to Free Play and Earn NOW!
                      </p>
                      <a
                        className="relative block mx-auto"
                        href="https://onelink.to/5tcwr9"
                        target="_blank"
                      >
                        <img
                          className="w-24 mt-1 cursor-pointer react-button mx-auto"
                          src="/assets/help.png"
                          alt="Button Join Now"
                        />
                        <p
                          className={`absolute top-0 left-0 w-full h-11 uppercase text-white flex items-center justify-center text-[12px]`}
                        >
                          Play GAME
                        </p>
                      </a>
                    </div>
                    <div className="p-2 mt-2">
                      <p className="text-[#4c2525] text-sm">Scan HERE</p>
                      <img src="mobileqr.png" className="w-[100px]" />
                    </div>
                  </div>
                  {/* <a
                    href="https://medium.com/@monsterra.io/monsterra-soft-launch-on-avalanche-kickstarted-join-and-play-free-to-win-3450-now-b10cf33b1ce9"
                    target="_blank"
                  >
                    <img
                      className="w-28 mt-1 cursor-pointer react-button mx-auto"
                      src="/assets/btn-join.png"
                      alt="Button Join Now"
                    />
                  </a> */}
                </div>
                {/* <img src="/assets/tutorial-text.png" className="w-96" /> */}
                <img
                  onClick={() => {
                    setShow(false);
                  }}
                  src="assets/close.png"
                  className="absolute -top-5 -right-5 w-10 h-10 cursor-pointer transform hover:scale-125 duration-300"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
function Sharing() {
  const query = new URLSearchParams(window.location.search);
  const share = query.get("share");
  const [content, setContent] = useState(0);
  const [show, setShow] = useState(false);
  useEffect(() => {
    let shareInfo = getShareInfo();
    if (!shareInfo) {
      return;
    }
    setShow(true);
    setContent(shareInfo.shareIndex);
  }, [share]);
  const titleContent = [
    `Avalanche x Monsterra
    Soft Launch`,
    `Need help!`,
    `Need help!`,
    `Need help!`,
    `Need help!`,
    `Need help!`,
    `Need help!`,
    "Thank you!",
  ];
  const buttonContent = [
    `JOIN NOW`,
    `Help a hand`,
    `Help a hand`,
    `Help a hand`,
    `Help a hand`,
    `Help a hand`,
    `Help a hand`,
    "Close",
  ];
  const shareContent = [
    `Play Monsterra on Avalanche to get USDT, MSTR and other
    rewards of <span class="font-bold">$3450</span>`,
    `Your friend is eager for more sMAG. Let's help him enrich his kingdom with x2 sMAG rewards via this link`,
    `Your friend is entering fierce battles. He is running out of energy. Help him collects +20 energy NOW.`,
    `A weathly kingdom always owns a full storage of food. Give Your friend a favor with + 10000 Food hidden in this link`,
    `New Mongens are hatched will become level 10. Make this impossible magic come true to Your friend by clicking into this link`,
    `Your friend is tired of waiting for his Soulcore to be hatched. He need your help to reduce 50% hatching time. Give him a hand HERE (The reduction is based on the remaining time)`,
    `Your friend wants to collect his Soulcore as soon as possible. Entering this link to help him reduce 50% of breeding time. (The reduction is based on the remaining time)`,
    "Thanks for your help!",
  ];
  async function onHelpClick() {
    let shareInfo = getShareInfo();
    let url = "https://api.monsterra.io/sharing/active-share-url";
    let rs = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ key: shareInfo.shareKey }),
    });
    window.location.href = "https://monsterra.io";
  }
  function getShareInfo() {
    try {
      let [userId, shareId, uid] = share.split("-");
      let shareIndex = Number(shareId.split("_")[1]);
      return {
        userId: Number(userId),
        shareId,
        shareIndex,
        uid,
        shareKey: share,
      };
    } catch (error) {
      return null;
    }
  }
  return (
    show && (
      <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-70 z-[100]">
        <div className="flex w-full h-full items-center justify-center ">
          <div className="w-10/12 max-w-[800px] relative">
            <div className="w-full flex gap-4 relative items-center">
              <img
                src={"/assets/backed-board.webp"}
                className="absolute top-0 left-0 w-full h-full z-0"
              />
              <div className="relative px-10 py-20 flex gap-4 flex-col md:flex-row ">
                <div className="w-full md:w-1/3 z-1 relative">
                  <img src={`/assets/share/emoji.webp`} className="w-full" />
                </div>
                <div className="w-full md:w-2/3 z-1 relative">
                  <p>{shareContent[content]}</p>
                  <button
                    onClick={onHelpClick}
                    className="relative block mx-auto mt-4"
                    href="https://medium.com/@monsterra.io/monsterra-soft-launch-on-avalanche-kickstarted-join-and-play-free-to-win-3450-now-b10cf33b1ce9"
                    target="_blank"
                  >
                    <img
                      className="w-28 mt-1 cursor-pointer react-button mx-auto"
                      src="/assets/help.png"
                      alt="Button Join Now"
                    />
                    <p
                      className={`absolute top-0 left-0 w-full h-14 uppercase text-white flex items-center justify-center text-sm`}
                    >
                      Help a hand
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

import { useEffect, useState } from "react";

export default function Secret({ id, pos, cls }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let rs = localStorage.getItem("secret");
    if (Number(rs) % 7 === id) {
      setShow(true);
    }
  }, []);
  // return <img src="/assets/219.webp" className={`w-16 opacity-50 ${cls}`} />;
  return (
    show && (
      <img src="/assets/219.webp" className={`w-16 opacity-50 ${cls}`} />
      // <p className={`w-16 opacity-50 ${pos === "right" && "text-right"}`}>
      //   $219,000
      // </p>
    )
  );
}

/** @format */

import btnShowContract from "./contract/btn-show-contract.webp";
import btnTrade from "./contract/trade.png";
import { FitToViewport } from "react-fit-to-viewport";
import chainBnb from "./contract/chain-bnb-pc.png";
import chainAura from "./contract/aura.webp";
import chainAva from "./contract/chain-ava-pc.png";
import chainCherry from "./contract/cherryswap.png";
import chainOkex from "./contract/okex.webp";
import bg from "./contract/bg.png";
import magIcon from "./contract/mag.png";
import mstrIcon from "./contract/mstr.png";
import { useState } from "react";
import btnCopy from "./contract/btn-copy.png";
import btnCopied from "./contract/btn-copied.png";
import mexc from "./contract/mexc.png";
import pancake from "./contract/pancake.png";
import haloTrade from "./contract/halotrade.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import btnClose from "./contract/close.png";
import bgPc from "./contract/bg-pc.png";
import pangolin from "./contract/pangolin.webp";
const mstr = "mstr";
const mag = "mag";
const data = [
  {
    icon: chainBnb,
    mstr: "0x2290C6bD9560E6498dFDf10F9eCB17997CA131f2",
    mstr_trade: [
      {
        img: pancake,
        url: "https://pancakeswap.finance/swap?outputCurrency=0x2290C6bD9560E6498dFDf10F9eCB17997CA131f2&inputCurrency=0x55d398326f99059ff775485246999027b3197955&chainId=56",
      },
      {
        img: mexc,
        url: "https://www.mexc.com/exchange/MSTR_USDT",
      },
    ],
    mag: "0xd4c73fd18f732BC6EE9FB193D109B2eed815Df80",
    mag_trade: [
      {
        img: pancake,
        url: "https://pancakeswap.finance/swap?outputCurrency=0xd4c73fd18f732BC6EE9FB193D109B2eed815Df80&inputCurrency=0x2290C6bD9560E6498dFDf10F9eCB17997CA131f2&chainId=56",
      },
    ],
  },
  {
    icon: chainAva,
    mstr: "0xE397784960F814BA35C9Ee0BC4c9DFFdf86925f9",
    mag: "0x53B22d356F34e977e48921e07381DE0f8200b8e6",
    mstr_trade: [
      {
        img: pangolin,
        url: "https://app.pangolin.exchange/#/swap?inputCurrency=AVAX&outputCurrency=0xE397784960F814BA35C9Ee0BC4c9DFFdf86925f9",
      },
    ],
    mag_trade: [
      {
        img: pangolin,
        url: "https://app.pangolin.exchange/#/swap?inputCurrency=0xE397784960F814BA35C9Ee0BC4c9DFFdf86925f9&outputCurrency=0x53B22d356F34e977e48921e07381DE0f8200b8e6",
      },
    ],
  },
  // {
  //   icon: chainOkex,
  //   mstr: "0xE397784960F814BA35C9Ee0BC4c9DFFdf86925f9",
  //   mstr_trade: [
  //     {
  //       img: chainCherry,
  //       url: "https://www.cherryswap.net/#/swap?inputCurrency=OKT&outputCurrency=0xE397784960F814BA35C9Ee0BC4c9DFFdf86925f9",
  //     },
  //   ],
  //   mag: "0x53B22d356F34e977e48921e07381DE0f8200b8e6",
  //   mag_trade: [
  //     {
  //       img: chainCherry,
  //       url: "https://www.cherryswap.net/#/swap?inputCurrency=0xE397784960F814BA35C9Ee0BC4c9DFFdf86925f9&outputCurrency=0x53B22d356F34e977e48921e07381DE0f8200b8e6",
  //     },
  //   ],
  // },
  {
    icon: chainAura,
    mstr: "aura10jpl6rz59h6chrpx3edahntqthdmaemrc8eewvxge7e2hhxtltjqc2ucrm",
    mstr_trade: [
      {
        img: haloTrade,
        url: "https://halotrade.zone/swap?tokenA=AURA&tokenB=MSTR",
      },
    ],
    mag: "aura1qgt0tesm52tgdda5h8262rs00lnkagmky466gzlcx8dcvt48dl0se9ph3e",
    mag_trade: [
      {
        img: haloTrade,
        url: "https://halotrade.zone/swap?tokenA=MSTR&tokenB=MAG",
      },
    ],
  },
];

export default function Contract() {
  const [show, setShow] = useState(false);
  const [copy, setCopy] = useState("none");
  const [tab, setTab] = useState(0);
  function formatAddress(str) {
    return str;
    if (!str) return str;
    if (window.innerWidth < 1024 && str.length === 42) {
      return `${str.substring(0, 4)}...${str.substring(
        str.length - 4,
        str.length
      )}`;
    } else {
      return str;
    }
  }
  return (
    <>
      {!show && (
        <img
          alt="show contract btn"
          className="h-14 ipadpro:h-24 ipadpro:pb-2 fixed z-[51] bottom-2 left-[50%] transform translate-x-[-50%] cursor-pointer"
          src={btnShowContract}
          onClick={() => {
            setShow(true);
          }}
        />
      )}
      {show && (
        <div className="fixed w-screen h-screen ipadpro:h-[300px] bottom-auto top-0 ipadpro:top-auto ipadpro:bottom-0 left-0 bg-black ipadpro:bg-transparent bg-opacity-75 z-[51]">
          <div className="w-full flex h-full items-center ipadpro:items-end justify-center">
            <div className="w-[360px] ipadpro:w-[720px] relative">
              <img src={bg} className="w-full ipadpro:hidden" />
              <img src={bgPc} className="w-full hidden ipadpro:block " />
              <div className="w-full absolute top-0 left-0 z-10">
                <p className="uppercase text-[#504431] text-[16px] ipadpro:text-[26px] font-bold w-full text-center mt-4">
                  Official Contract Address
                </p>
                <img
                  src={btnClose}
                  className="absolute top-0 right-0 h-8 w-8 ipadpro:h-10 ipadpro:w-10 cursor-pointer"
                  onClick={() => {
                    setShow(false);
                  }}
                />
                <div className="flex justify-between w-[300px] ipadpro:w-[500px] mx-auto mt-1 ipadpro:mt-2">
                  {data.map((item, index) => (
                    <div
                      className={`${
                        tab === index
                          ? "opacity-100 border-[#504431]"
                          : "opacity-40 border-transparent"
                      } duration-500 cursor-pointer border-b-2 p-1`}
                    >
                      <img
                        src={item.icon}
                        className="h-10"
                        onClick={() => {
                          setTab(index);
                        }}
                      />
                    </div>
                  ))}
                </div>
                {data[tab] !== undefined ? (
                  <div>
                    <div className="pl-2 mt-6 w-[230px] ipadpro:w-[560px] mx-auto">
                      <div className="flex justify-between items-center">
                        <div>
                          <img src={mstrIcon} className="w-20" />
                        </div>
                        <span
                          className="text-[#434343] ipadpro:text-base w-full overflow-hidden"
                          style={{ fontSize: 12 }}
                        >
                          {formatAddress(data[tab].mstr)}
                        </span>
                        <div className="cursor-pointer">
                          <CopyToClipboard
                            text={data[tab].mstr}
                            onCopy={() => {
                              setCopy("mstr");
                            }}
                          >
                            {copy === "mstr" ? (
                              <img
                                src={btnCopied}
                                className="h-5 ipadpro:h-6"
                              />
                            ) : (
                              <img src={btnCopy} className="h-5 ipadpro:h-6" />
                            )}
                          </CopyToClipboard>
                        </div>
                      </div>
                      {data[tab]?.mstr_trade?.length > 0 && (
                        <div className="mt-1 mx-auto flex justify-start items-start ipadpro:items-center gap-4">
                          <div className="w-[80px]">
                            <img
                              src={btnTrade}
                              className="mt-2 ipadprod:mt-0 h-3 ipadpro:h-4 w-auto object-contain"
                            />
                          </div>
                          <div className="flex flex-col ipadpro:flex-row items-end gap-2 justify-start">
                            {data[tab].mstr_trade.map((item) => (
                              <a href={item.url} target="_blank">
                                <img
                                  src={item.img}
                                  className="h-7 ipadpro:h-6 w-auto"
                                />
                              </a>
                            ))}
                          </div>
                        </div>
                      )}
                      <div className="flex justify-between mt-2 items-center">
                        <div>
                          <img src={magIcon} className="w-20" />
                        </div>
                        <span
                          style={{ fontSize: 12 }}
                          className="text-[#434343]  ipadpro:text-base w-full overflow-hidden"
                        >
                          {formatAddress(data[tab].mag)}
                        </span>
                        <div className="cursor-pointer">
                          <CopyToClipboard
                            text={data[tab].mag}
                            onCopy={() => {
                              setCopy("mag");
                            }}
                          >
                            {copy === "mag" ? (
                              <img
                                src={btnCopied}
                                className="h-5 ipadpro:h-6"
                              />
                            ) : (
                              <img src={btnCopy} className="h-5 ipadpro:h-6" />
                            )}
                          </CopyToClipboard>
                        </div>
                      </div>
                      {data[tab].mag_trade?.length > 0 && (
                        <div className="mt-1 mx-auto flex justify-start items-start ipadpro:items-center gap-4">
                          <div className="w-[80px]">
                            <img
                              src={btnTrade}
                              className="mt-2 ipadprod:mt-0 h-3 ipadpro:h-4 w-auto object-contain"
                            />
                          </div>
                          <div className="flex flex-col ipadpro:flex-row items-end gap-2 justify-start">
                            {data[tab].mag_trade.map((item) => (
                              <a href={item.url} target="_blank">
                                <img
                                  src={item.img}
                                  className="h-7 ipadpro:h-6 w-auto"
                                />
                              </a>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="pl-2 w-[256px] mx-auto mt-16">
                    <p className="text-center text-[#434343]">Coming soon</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

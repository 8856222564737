import bgWeb from "styles/assets/backers/backed-web-bg.webp";
import bgMb from "styles/assets/backers/bg-mb.webp";
import backerWeb from "styles/assets/backers/backer-web.webp";
import backerMb from "styles/assets/backers/backer-mb.webp";
import partnerWeb from "styles/assets/backers/partner-web.webp";
import partnerMb from "styles/assets/backers/partner-mb.webp";
import Slider from "react-slick";
export default function BackedBy(props) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "h-full",
  };
  return (
    <div className="bg-[#211F1A]">
      <div className="bg-battle_front_black_bg bg-cover max-w-1920 mx-auto px-5 md:px-20">
        <div className="hidden md:block w-full pt-10 pb-16">
          <div
            // style={{ backgroundImage: `url(${bgWeb})` }}
            className="bg-cover bg-no-repeat h-full pb-1 pt-20"
          >
            <Slider {...settings}>
              <div className="nooutline">
                <img
                  src={backerWeb}
                  className="self-center justify-center mx-auto w-auto h-full px-4"
                  style={{ maxHeight: "70vh" }}
                  alt="backed bg"
                />
              </div>
              <div className="nooutline">
                <img
                  src={partnerWeb}
                  className="self-center justify-center mx-auto h-full w-auto"
                  style={{ maxHeight: "70vh" }}
                />
              </div>
            </Slider>
          </div>
        </div>
        <div className="md:hidden flex items-center justify-center  pt-[40px] pb-[60px] select-none">
          <div
            // style={{ backgroundImage: `url(${bgMb})` }}
            className="bg-contain bg-no-repeat w-full"
          >
            <Slider {...settings}>
              <div className="nooutline">
                <img src={backerMb} />
              </div>
              <div className="nooutline">
                <img src={partnerMb} />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

/** @format */

export default function Tokenomic(props) {
  return (
    <div className="bg-[#221F1B]">
      <div className="hidden md:flex bg-tokenomic max-w-1920 mx-auto bg-cover bg-center  flex-col items-center justify-start max-h-[1500px] pb-12 text-white">
        <p
          className={"font-black text-5xl pt-20 pb-6 select-none"}
          style={{ fontFamily: "CookieRun1" }}
        >
          MSTR TOKEN & TOKENOMIC
        </p>
        <div className="relative">
          <div>
            <img
              width="1338"
              height="803"
              src={"/assets/tokenomic/board.webp"}
              alt={"Tokenomic Paper"}
            />
          </div>
          <div className="absolute top-0 pt-16 lg:pt-20 xl:py-[110px] w-full flex flex-row justify-center pr-12 lg:pr-14 xl:pr-20 pl-6 lg:pl-14 xl:pl-20">
            <div className="flex flex-col w-1/2 pl-7 xl:pl-16 justify-around">
              <div>
                <div className="flex flex-row w-full lg:w-5/6  h-[40px] lg:h-[50px] xl:h-[70px] items-center border-t-2 border-borderTable">
                  <p className="w-[160px] lg:w-[220px] font-bold text-tableTitle text-base xl:text-xl">
                    Token Name
                  </p>
                  <p className="text-valurTable text-sm xl:text-xl">
                    Monsterra
                  </p>
                </div>
                <div className="flex flex-row w-full lg:w-5/6 h-[40px] lg:h-[50px] xl:h-[70px] items-center border-t-2 border-borderTable">
                  <p className="w-[160px] lg:w-[220px] font-bold text-tableTitle text-base xl:text-xl">
                    Symbol
                  </p>
                  <p className="text-valurTable text-sm xl:text-xl">MSTR</p>
                </div>
                <div className="flex flex-row w-full lg:w-5/6 h-[40px] lg:h-[50px] xl:h-[70px] items-center border-t-2 border-borderTable">
                  <p className="w-[160px] lg:w-[220px] font-bold text-tableTitle text-base xl:text-xl">
                    Chain
                  </p>
                  <p className="text-valurTable text-sm xl:text-xl">
                    BNB, AVAX, AURA
                  </p>
                </div>
                <div className="flex flex-row w-full lg:w-5/6 h-[40px] lg:h-[50px] xl:h-[70px] items-center border-t-2 border-borderTable">
                  <p className="w-[160px] lg:w-[220px] font-bold text-tableTitle text-base xl:text-xl">
                    Total Supply
                  </p>
                  <p className="text-valurTable text-sm xl:text-xl">
                    100,000,000
                  </p>
                </div>
                <div className="flex flex-row w-full lg:w-5/6 h-[40px] lg:h-[50px] xl:h-[70px] items-center border-t-2 border-borderTable">
                  <p className="w-[160px] lg:w-[220px] font-bold text-tableTitle text-base xl:text-xl">
                    Hard Cap
                  </p>
                  <p className="text-valurTable text-sm xl:text-xl">
                    $1,960,000
                  </p>
                </div>
                <div className="flex flex-row w-full lg:w-5/6 h-[40px] lg:h-[50px] xl:h-[70px] items-center border-t-2 border-b-2 border-borderTable">
                  <p className="w-[160px] lg:w-[220px] font-bold text-tableTitle text-base xl:text-xl">
                    Total for Sale
                  </p>
                  <p className="text-valurTable text-sm xl:text-xl">
                    15,000,000 (15%)
                  </p>
                </div>
              </div>
              <p className=" text-sm lg:text-lg xl:text-xl 2xl:text-xl text-scriptTable pb-2 w-[440px] lg:w-[550px] xl:w-[605px]">
                MSTR token on multi-chain (BNB, AVAX, AURA) are used mainly for
                interacting with Monsterra metaverse including NFT transaction
                payment, advanced in-game upgrade, community event and DeFi
                utility.
              </p>
            </div>
            <div className="w-1/2 pl-7">
              <img
                src="/assets/tokenomic/chart.webp"
                alt="tokenomic chart"
                width="1082"
                height="1126"
              />
            </div>
          </div>
        </div>
        <p className={"font-black text-3xl pt-8 select-none"}>POWERED BY</p>
        <div className="flex mt-6 gap-6">
          <div className="flex items-center flex-col">
            <img
              src="/assets/chain/bsc.webp"
              className="w-16 h-16"
              alt="bsc chain"
            />
            <p className="text-xl w-full text-center mt-2">BNB Chain</p>
          </div>
          <div className="flex items-center flex-col">
            <img
              src="/assets/chain/avalanche.webp"
              className="w-16 h-16"
              alt="avalance chain"
            />
            <p className="text-xl w-full text-center mt-2">AVAX Network</p>
          </div>
          {/* <div className="flex items-center flex-col">
            <img
              src="/assets/chain/okex.webp"
              className="w-16 h-16"
              alt="okex chain"
            />
            <p className="text-xl w-full text-center mt-2">OKT Chain</p>
          </div> */}
          <div className="flex items-center flex-col">
            <img
              src="/assets/chain/aura.webp"
              className="w-16 h-16"
              alt="aura chain"
            />
            <p className="text-xl w-full text-center mt-2">Aura Network</p>
          </div>
        </div>
      </div>
      <div className="md:hidden flex flex-col bg-tokenomic bg-cover bg-center items-center  text-white text-center pb-10">
        <p
          className={"font-black text-3xl pt-20   select-none"}
          style={{ fontFamily: "CookieRun1" }}
        >
          <span>MSTR TOKEN </span>
        </p>
        <p
          className={"font-black text-3xl pb-6  select-none"}
          style={{ fontFamily: "CookieRun1" }}
        >
          <span>& TOKENOMIC</span>
        </p>
        <div className="relative">
          <div>
            <img
              className={" "}
              src={"/assets/tokenomic/board-mb.webp"}
              alt={"Tokenomic Paper"}
            />
          </div>
          <div className="absolute flex flex-col top-0 mt-[18vw]">
            <div className="w-full px-[5vw]">
              <img
                src="/assets/tokenomic/chart.webp"
                alt="tokenomics chart"
                width="1082"
                height="1126"
              />
            </div>
            <div className="flex flex-col w-full text-left justify-between px-[6vw] pt-7">
              <div>
                <div className="flex flex-row w-full h-[50px] items-center border-t-2 border-borderTable">
                  <p className="w-1/2 font-bold text-tableTitle text-sm">
                    Token Name
                  </p>
                  <p className="text-valurTable text-sm">Monsterra</p>
                </div>
                <div className="flex flex-row w-full h-[50px] items-center border-t-2 border-borderTable">
                  <p className="w-1/2 font-bold text-tableTitle text-sm">
                    Symbol
                  </p>
                  <p className="text-valurTable text-sm">MSTR</p>
                </div>
                <div className="flex flex-row w-full h-[50px] items-center border-t-2 border-borderTable">
                  <p className="w-1/2 font-bold text-tableTitle text-sm">
                    Chain
                  </p>
                  <p className="text-valurTable text-sm">BNB Chain</p>
                </div>
                <div className="flex flex-row w-full h-[50px] items-center border-t-2 border-borderTable">
                  <p className="w-1/2 font-bold text-tableTitle text-sm">
                    Total Supply
                  </p>
                  <p className="text-valurTable text-sm">100,000,000</p>
                </div>
                <div className="flex flex-row w-full h-[50px] items-center border-t-2 border-borderTable">
                  <p className="w-1/2 font-bold text-tableTitle text-sm">
                    Hard Cap
                  </p>
                  <p className="text-valurTable text-sm">$1,960,000</p>
                </div>
                <div className="flex flex-row w-full h-[50px] items-center border-t-2 border-b-2 border-borderTable">
                  <p className="w-1/2 font-bold text-tableTitle text-sm">
                    Total for Sale
                  </p>
                  <p className="text-valurTable text-sm">15,000,000 (15%)</p>
                </div>
              </div>
              <p className="text-sm text-scriptTable pt-4 ">
                MSTR token on multi-chain (BNB & AVAX & OKX) are used mainly for
                interacting with Monsterra metaverse including NFT transaction
                payment, advanced in-game upgrade, community event and DeFi
                utility.
              </p>
            </div>
          </div>
        </div>
        <p className={"font-black text-3xl pt-12 select-none"}>POWERED BY</p>
        <div className="flex mt-4 flex-row">
          <div className="flex flex-col items-center justify-center mr-10">
            <img
              src="/assets/chain/bsc.webp"
              className="w-16 h-16"
              alt="bsc chain"
            />
            <p className="w-full text-center mt-2">BNB Chain</p>
          </div>
          <div className="flex flex-col items-center justify-center mr-10">
            <img
              src="/assets/chain/avalanche.webp"
              className="w-16 h-16"
              alt="avalance chain"
            />
            <p className="w-full text-center mt-2">AVAX Network</p>
          </div>
          {/* <div className="flex flex-col items-center justify-center">
            <img
              src="/assets/chain/okex.webp"
              className="w-16 h-16"
              alt="okex chain"
            />
            <p className="w-full text-center mt-2">OKTChain</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

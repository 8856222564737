import { useEffect, useState } from "react";
import bottomSmoke from "styles/assets/header/bottom-smoke.webp";
var Scroll = require("react-scroll");
var Events = Scroll.Events;
export default function Story(props) {
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100 && window.scrollY < 1000) {
        setIsShow(true);
      }
    });
  });
  return (
    <div className="bg-story bg-cover bg-center flex flex-col items-center justify-center h-screen relative">
      <img
        className="w-full absolute top-0 left-0 z-30"
        src={bottomSmoke}
        alt="monsterra"
        width="100%"
        height="100%"
      />
      <div className="flex flex-col justify-center items-center">
        <p className={`font-bold text-grey2 text-2xl mb-3`}>MONSTER TERRA</p>
        <div className={`px-8`}>
          <img
            src="/assets/monster-story.webp"
            alt="story"
            width="100%"
            height="100%"
          />
        </div>
        <div className="flex flex-col text-center w-5/6 md:w-[409px] gap-4 mt-10">
          <p
            className={`text-base text-story ${
              isShow && "story-appear-anim"
            } anim-delay-2 opacity-0`}
          >
            "Mongens have been around human forever and human must learn how to
            coexist with..
          </p>
          <p
            className={`text-base text-story ${
              isShow && "story-appear-anim"
            } anim-delay-3 opacity-0`}
          >
            They grow and build so strong empires that it is impossible to think
            of a society without them.
          </p>
          <p
            className={`text-base text-story ${
              isShow && "story-appear-anim"
            } anim-delay-4 opacity-0`}
          >
            Monster Terra is the most prosperous empire raised by 5 different
            races of Mongens including Beast, Tectos, Mystic, Celest, and Chaos.
          </p>
          <p
            className={`text-base text-story ${
              isShow && "story-appear-anim"
            } anim-delay-5 opacity-0`}
          >
            It is the beginning of an farming ecosystem, competition and
            fight...”
          </p>
        </div>
      </div>
    </div>
  );
}

import Banner from "components/Banner";
import Story from "components/Story";
import Tokenomic from "../components/Tokenomic";
import LandSection from "../components/LandSection";
import PlayModeAnimation from "../components/PlayModeAnimation";
import Roadmap from "../components/Roadmap";
import BackedBy from "../components/BackedBy";
import TeamMember from "../components/TeamMember";
import { Element } from "react-scroll";
import Countdown from "popup/countdown";
import Contract from "components/Contract";
import IDO from "popup/ido";
export default function Main(props) {
  return (
    <main className="flex flex-col">
      <Element name="banner">
        <Banner />
      </Element>
      <Element name="story">
        <Story showStory={props.showStory} />
      </Element>
      <Element name="land">
        <LandSection increaseLoading={props.increaseLoading} />
      </Element>
      <Element name="playmode">
        <PlayModeAnimation />
      </Element>
      <Element name="tokenomic">
        <Tokenomic />
      </Element>
      <Element name="roadmap">
        <Roadmap />
      </Element>
      <BackedBy />
      <TeamMember />
      {/* <Countdown /> */}
      <Contract />
    </main>
  );
}

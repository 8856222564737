export default function Social(props) {
  return (
    <div
      className="hidden md:flex flex-col fixed items-center justify-center w-20 z-50 h-full right-0 cursor-pointer  gap"
      style={{ right: "-4px", gap: "2px" }}
    >
      <img
        className="react-social"
        onClick={() =>
          window.open(" https://twitter.com/Monsterra_P2E ", "_blank").focus()
        }
        src="/assets/social/Twitter.webp"
        alt="Twitter"
        width="100%"
        height="100%"
      />
      <img
        className="react-social"
        onClick={() =>
          window.open("https://t.me/monsterra_official", "_blank").focus()
        }
        src="/assets/social/Telegram.webp"
        alt="Telegram"
        width="100%"
        height="100%"
      />
      {/* <img
        className="react-social"
        onClick={() =>
          window.open("https://discord.gg/R5uG9WCyr7", "_blank").focus()
        }
        src="/assets/social/Discord.webp"
        alt="Discord"
        width="100%"
        height="100%"
      /> */}
      <img
        className="react-social"
        onClick={() =>
          window
            .open(
              "https://www.facebook.com/Monsterra-P2E-Game-100591379141319",
              "_blank"
            )
            .focus()
        }
        src="/assets/social/Facebook.webp"
        alt="Facebook"
        width="100%"
        height="100%"
      />
      <img
        className="react-social"
        onClick={() =>
          window.open("https://medium.com/@monsterra.io", "_blank").focus()
        }
        src="/assets/social/medium.webp"
        alt="Medium"
        width="100%"
        height="100%"
      />
      <img
        className="react-social"
        onClick={() =>
          window
            .open("https://www.youtube.com/MonsterraNFTGame", "_blank")
            .focus()
        }
        src="/assets/social/Youtube.webp"
        alt="Youtube"
        width="100%"
        height="100%"
      />
      <img
        className="react-social"
        onClick={() =>
          window
            .open("https://www.tiktok.com/@monsterra_nftgame", "_blank")
            .focus()
        }
        src="/assets/social/Tiktok.webp"
        alt="Tiktok"
        width="100%"
        height="100%"
      />
    </div>
  );
}

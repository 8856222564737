import { useState, useEffect } from "react";
import Secret from "./Secret";
let players = {};
export default function LandSection({ increaseLoading }) {
  const [activeSkin, setActiveSkin] = useState("mongen");
  const [activeIndex, setActiveIndex] = useState(0);
  const [isGL, setIsGL] = useState(false);
  const [image, setImage] = useState("/assets/mongen/tectos/mongen.webp");
  const [imageSize, setImageSize] = useState(80);
  function calculateSize() {
    if (window.innerWidth >= 1920) {
      return {
        right: 10 - (1440 - 1920) / 2,
        width: 600,
        height: 600,
      };
    } else if (window.innerWidth > 1600) {
      return {
        right: 80 - (1440 - window.innerWidth) / 2,
        width: 500,
        height: 500,
      };
    } else if (window.innerWidth > 1024) {
      return {
        right: 180 - (1440 - window.innerWidth) / 2,
        width: 500,
        height: 500,
      };
    } else if (window.innerWidth > 768) {
      return {
        right: 300 - (1440 - window.innerWidth) / 2,
        width: 400,
        height: 400,
      };
    } else {
      return {
        right: (window.innerWidth - 350) / 2,
        width: 350,
        height: 350,
      };
    }
  }
  const [size, setSize] = useState(calculateSize());
  function initPlayer() {
    window.addEventListener("resize", () => {
      setSize(calculateSize());
    });
    players = {
      "tectos-egg-player": new window.spine.SpinePlayer("tectos-egg-player", {
        animation: "Hold",
        showControls: false,
        skin: "Skin3",
        premultipliedAlpha: true,
        backgroundColor: "#00000000",
        alpha: true,
        defaultMix: 1,
        jsonUrl: "/assets/soulcores/Tectos/Egg_Tectos.json",
        atlasUrl: "/assets/soulcores/Tectos/Egg_Tectos.atlas.txt",
        success: () => {
          setIsGL(true);
          increaseLoading();
        },
        error: () => {
          setIsGL(false);
          increaseLoading();
        },
        viewport: {
          padLeft: "60%",
          padRight: "60%",
          padTop: "60%",
        },
      }),
      "mystic-egg-player": new window.spine.SpinePlayer("mystic-egg-player", {
        animation: "Hold",
        showControls: false,
        skin: "Skin3",
        premultipliedAlpha: true,
        backgroundColor: "#00000000",
        alpha: true,
        defaultMix: 1,
        jsonUrl: "/assets/soulcores/Mythic/Egg_Mythic.json",
        atlasUrl: "/assets/soulcores/Mythic/Egg_Mythic.atlas.txt",
        success: () => {
          setIsGL(true);
          increaseLoading();
        },
        error: () => {
          setIsGL(false);
          increaseLoading();
        },
        viewport: {
          padLeft: "60%",
          padRight: "60%",
          padTop: "60%",
        },
      }),
      "beast-egg-player": new window.spine.SpinePlayer("beast-egg-player", {
        animation: "Hold",
        showControls: false,
        skin: "Skin3",
        premultipliedAlpha: true,
        backgroundColor: "#00000000",
        alpha: true,
        defaultMix: 1,
        jsonUrl: "/assets/soulcores/Beast/Egg_Beast.json",
        atlasUrl: "/assets/soulcores/Beast/Egg_Beast.atlas.txt",
        success: () => {
          setIsGL(true);
          increaseLoading();
        },
        error: () => {
          setIsGL(false);
          increaseLoading();
        },
        viewport: {
          padLeft: "60%",
          padRight: "60%",
          padTop: "60%",
        },
      }),
      "celest-egg-player": new window.spine.SpinePlayer("celest-egg-player", {
        animation: "Hold",
        showControls: false,
        skin: "Skin3",
        premultipliedAlpha: true,
        backgroundColor: "#00000000",
        alpha: true,
        defaultMix: 1,
        jsonUrl: "/assets/soulcores/Celest/Egg_Celest.json",
        atlasUrl: "/assets/soulcores/Celest/Egg_Celest.atlas.txt",
        success: () => {
          setIsGL(true);
          increaseLoading();
        },
        error: () => {
          setIsGL(false);
          increaseLoading();
        },
        viewport: {
          padLeft: "60%",
          padRight: "60%",
          padTop: "60%",
        },
      }),
      "chaos-egg-player": new window.spine.SpinePlayer("chaos-egg-player", {
        animation: "Hold",
        showControls: false,
        skin: "Skin3",
        premultipliedAlpha: true,
        backgroundColor: "#00000000",
        alpha: true,
        defaultMix: 1,
        jsonUrl: "/assets/soulcores/Chaos/Egg_Chaos.json",
        atlasUrl: "/assets/soulcores/Chaos/Egg_Chaos.atlas.txt",
        success: () => {
          setIsGL(true);
          increaseLoading();
        },
        error: () => {
          setIsGL(false);
          increaseLoading();
        },
        viewport: {
          padLeft: "40%",
          padRight: "40%",
          padTop: "40%",
        },
      }),
      //mongen
      "tectos-mongen-player": new window.spine.SpinePlayer(
        "tectos-mongen-player",
        {
          animation: "Tectos/Tectos_Idle",
          showControls: false,
          skin: "s3all",
          premultipliedAlpha: true,
          backgroundColor: "#00000000",
          alpha: true,
          defaultMix: 1,
          jsonUrl: "/assets/mongen/tectos/Pets.json",
          atlasUrl: "/assets/mongen/tectos/Pets.atlas",
          success: () => {
            setIsGL(true);
            increaseLoading();
          },
          error: () => {
            setIsGL(false);
            increaseLoading();
          },
          viewport: {
            padLeft: "70%",
            padRight: "70%",
            padTop: "70%",
          },
        }
      ),
      "mystic-mongen-player": new window.spine.SpinePlayer(
        "mystic-mongen-player",
        {
          animation: "Mythic/Mythic_Idle",
          showControls: false,
          skin: "s6all",
          premultipliedAlpha: true,
          backgroundColor: "#00000000",
          alpha: true,
          defaultMix: 1,
          jsonUrl: "/assets/mongen/mystic/Pets.json",
          atlasUrl: "/assets/mongen/mystic/Pets.atlas",
          success: () => {
            setIsGL(true);
            increaseLoading();
          },
          error: () => {
            setIsGL(false);
            increaseLoading();
          },
          viewport: {
            padLeft: "40%",
            padRight: "40%",
            padTop: "40%",
          },
        }
      ),
      "beast-mongen-player": new window.spine.SpinePlayer(
        "beast-mongen-player",
        {
          animation: "Beast/Beast_Idle",
          showControls: false,
          skin: "s7all",
          premultipliedAlpha: true,
          backgroundColor: "#00000000",
          alpha: true,
          defaultMix: 1,
          jsonUrl: "/assets/mongen/beast/Pets.json",
          atlasUrl: "/assets/mongen/beast/Pets.atlas",
          success: () => {
            setIsGL(true);
            increaseLoading();
          },
          error: () => {
            setIsGL(false);
            increaseLoading();
          },
          viewport: {
            padLeft: "20%",
            padRight: "20%",
            padTop: "20%",
          },
        }
      ),
      "celest-mongen-player": new window.spine.SpinePlayer(
        "celest-mongen-player",
        {
          animation: "Celest/Celest_Idle",
          showControls: false,
          skin: "skin1",
          premultipliedAlpha: true,
          backgroundColor: "#00000000",
          alpha: true,
          defaultMix: 1,
          jsonUrl: "/assets/mongen/celest/Pets.json",
          atlasUrl: "/assets/mongen/celest/Pets.atlas.txt",
          success: () => {
            setIsGL(true);
            increaseLoading();
          },
          error: (err) => {
            console.log("gl error", err);
            setIsGL(false);
            increaseLoading();
          },
          viewport: {
            padLeft: "30%",
            padRight: "30%",
            padTop: "30%",
          },
        }
      ),
      "chaos-mongen-player": new window.spine.SpinePlayer(
        "chaos-mongen-player",
        {
          animation: "Chaos/Chaos_Idle",
          showControls: false,
          skin: "skin1",
          premultipliedAlpha: true,
          backgroundColor: "#00000000",
          alpha: true,
          defaultMix: 1,
          jsonUrl: "/assets/mongen/chaos/Pets.json",
          atlasUrl: "/assets/mongen/chaos/Pets.atlas.txt",
          success: () => {
            setIsGL(true);
            increaseLoading();
          },
          error: (err) => {
            console.log("gl error", err);
            setIsGL(false);
            increaseLoading();
          },
          viewport: {
            padLeft: "10%",
            padRight: "10%",
            padTop: "10%",
          },
        }
      ),
    };
  }
  useEffect(() => {
    let time = setInterval(() => {
      if (window.spine) {
        clearInterval(time);
        initPlayer();
      }
    }, 100);
  }, []);
  const listTab = [
    {
      id: "tectos",
      name: "Tectos",
      describe:
        "Tectos is very strong. It has enough power to easily drag a boulder weighing more than a ton. This Mongens also has powerful vision that lets it see through deep smoke.",
    },
    {
      id: "mystic",
      name: "Mystic",
      describe:
        "Not only does it perceive auras, but it has also gained the power to control them. It employs them in battle.",
    },
    {
      id: "beast",
      name: "Beast",
      describe:
        "Beasts are better at defense than offense. The hard scales that cover its strong body serve as excellent protection from any attack.",
    },
    {
      id: "celest",
      name: "Celest",
      describe:
        "It’s said that this Mongen battles in order to protect the ideal world that will exist in the future for people and Mongens.",
    },
    {
      id: "chaos",
      name: "Chaos",
      describe:
        "It looks somehow pained as it rages around in search of the life force of living creatures, which serves as its energy. It’s apparently from underworld.",
    },
  ];

  const [tabSelect, setTabSelect] = useState({
    id: "tectos",
    name: "Tectos",
    describe:
      "Tectos is very strong. It has enough power to easily drag a boulder weighing more than a ton. This Mongens also has powerful vision that lets it see through deep smoke.",
  });

  const onChangeTab = (idTab) => {
    setShowDropdown(false);
    setActiveSkin("mongen");
    setActiveIndex(0);
    setImage(`/assets/mongen/${idTab.id}/mongen.webp`);
    if (idTab.id === "beast") {
      setImageSize(80);
    } else {
      setImageSize(70);
    }
    setTabSelect(idTab);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const clickDropdownLand = () => {
    setShowDropdown(!showDropdown);
    checkBrowser();
  };
  const [isSafari, setIsSafari] = useState(false);
  const checkBrowser = () => {
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  };
  function onEggClick(index) {
    setActiveIndex(index);
    let key = `${tabSelect.id}-${index ? "egg" : "mongen"}-player`;
    if (index) {
      setImageSize(70);
      setActiveSkin("skin");
      setImage(`/assets/eggs/${tabSelect.id}/${index - 1}.webp`);
    } else {
      if (tabSelect.id === "beast") {
        setImageSize(80);
      } else {
        setImageSize(70);
      }

      setActiveSkin("mongen");
      setImage(`/assets/mongen/${tabSelect.id}/mongen.webp`);
    }

    let player = players[key];
    if (!player || !player?.skeleton) {
      return;
    }
    if (index) {
      player.skeleton.setSkinByName(`Skin${index}`);
      player.skeleton.setToSetupPose();
    }
  }
  return (
    <div className="relative bg-[#332929]">
      <div className="block mx-auto max-w-1920">
        <div className="relative pt-0 md:pt-[47%]" style={{ height: 0 }}>
          <div className="absolute top-0 left-0 w-full h-full">
            <div className="hidden md:flex flex-col items-center justify-center w-full h-full relative">
              <div className="absolute top-0 z-10">
                <img
                  className="h-3"
                  src="/assets/line.webp"
                  alt="line"
                  width="882"
                  height="13px"
                />
              </div>
              <div className="absolute bottom-0 z-10">
                <img
                  className="h-3"
                  src="/assets/line.webp"
                  alt="line"
                  width="882"
                  height="13px"
                />
              </div>
              <div className="absolute h-[60px] lg:h-[80px] xl:h-[100px] top-0 z-30 flex flex-col bg-a w-full mt-3"></div>
              <div className="absolute w-[100vw] max-w-[1750px] flex flex-col justify-between z-40 h-full px-12">
                <div>
                  <div className="top-0 flex flex-row gap-4 justify-between pt-0 lg:pt-3 xl:pt-5 lg py-1">
                    <>
                      {listTab.map((tab, index) => {
                        let srcTab =
                          tab.id === tabSelect.id
                            ? "/assets/land-section/land-section-tab1.svg"
                            : "/assets/land-section/land-section-tab.svg";
                        let textClass =
                          tab.id === tabSelect.id
                            ? "font-bold text-tint text-[2vw]"
                            : "font-bold  text-[2vw] text-white";
                        return (
                          <div
                            key={index}
                            className={`relative w-72 h-20 justify-center items-center flex text-white cursor-pointer react-button ${
                              tab.id !== tabSelect.id && "react-tab"
                            }`}
                            onClick={() => onChangeTab(tab)}
                          >
                            <img
                              className="absolute "
                              src={srcTab}
                              alt="land"
                            />
                            <p className={textClass}>{tab.name}</p>
                          </div>
                        );
                      })}
                    </>
                  </div>
                  {tabSelect.id === "beast" && (
                    <Secret id={0} cls="absolute right-28 bottom-56" />
                  )}
                  {tabSelect.id === "celest" && (
                    <Secret id={2} cls="absolute right-72 bottom-10" />
                  )}
                  <div
                    className="flex flex-col absolute"
                    style={{ bottom: "37%" }}
                  >
                    {tabSelect.id === "mystic" && <Secret id={1} />}

                    <div
                      className="text-[36px] lg:text-[60px] text-white"
                      style={{ fontFamily: "CookieRun1" }}
                    >
                      {tabSelect.describe ? tabSelect.name.toUpperCase() : ""}
                    </div>
                    <div
                      className="text-base lg:text-xl text-greyBlur1 w-[500px]"
                      style={{ marginTop: "2%" }}
                    >
                      {tabSelect.describe}
                    </div>
                  </div>
                </div>
                {tabSelect.describe ? (
                  <div
                    className="absolute items-center flex flex-row justify-start"
                    style={{ bottom: "4%" }}
                  >
                    <div
                      style={{ width: "15%" }}
                      className={`cursor-pointer ${
                        activeIndex !== 0 && "react-mongen"
                      }`}
                    >
                      <img
                        onClick={() => {
                          onEggClick(0);
                        }}
                        src={`/assets/land-section/${tabSelect.id}/monster-box.webp`}
                        alt="monster box"
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <div className="flex flex-row gap-6 justify-start w-full">
                      <div
                        className={`flex flex-col items-center cursor-pointer ${
                          activeIndex !== 1 && "react-mongen"
                        }`}
                        style={{ width: "8%" }}
                        onClick={() => {
                          onEggClick(1);
                        }}
                      >
                        <div className="w-full pt-6">
                          <img
                            width="100%"
                            height="100%"
                            className="w-full"
                            src={`/assets/land-section/${tabSelect.id}/egg-common.webp`}
                            alt="egg common"
                          />
                        </div>
                        <p className="text-base text-white text-shadow">
                          Common
                        </p>
                      </div>
                      <div
                        style={{ width: "8%" }}
                        className={`flex flex-col items-center cursor-pointer ${
                          activeIndex !== 2 && "react-mongen"
                        }`}
                        onClick={() => {
                          onEggClick(2);
                        }}
                      >
                        <div className="pt-6 w-full">
                          <img
                            width="100%"
                            height="100%"
                            src={`/assets/land-section/${tabSelect.id}/egg-uncommon.webp`}
                            alt="egg uncommon"
                          />
                        </div>
                        <p className="text-base text-white text-shadow">
                          Uncommon
                        </p>
                      </div>
                      <div
                        style={{ width: "8%" }}
                        className={`flex flex-col items-center cursor-pointer ${
                          activeIndex !== 3 && "react-mongen"
                        }`}
                        onClick={() => {
                          onEggClick(3);
                        }}
                      >
                        <div className="w-full pt-6">
                          <img
                            width="100%"
                            height="100%"
                            src={`/assets/land-section/${tabSelect.id}/egg-rare.webp`}
                            alt="egg rare"
                          />
                        </div>
                        <p className="text-base text-white text-shadow">Rare</p>
                      </div>
                      <div
                        style={{ width: "8%" }}
                        className={`flex flex-col items-center cursor-pointer ${
                          activeIndex !== 4 && "react-mongen"
                        }`}
                        onClick={() => {
                          onEggClick(4);
                        }}
                      >
                        <div className="w-full pt-6">
                          <img
                            width="100%"
                            height="100%"
                            src={`/assets/land-section/${tabSelect.id}/egg-epic.webp`}
                            alt="egg lock"
                          />
                        </div>
                        <p className="text-base text-white text-shadow">Epic</p>
                      </div>
                      <div
                        className="flex flex-col items-center  react-mongen cursor-pointer"
                        style={{ width: "8%" }}
                      >
                        <div className="w-full pt-6">
                          <img
                            width="100%"
                            height="100%"
                            src={`/assets/land-section/${tabSelect.id}/egg-lock.webp`}
                            alt="egg lock"
                          />
                        </div>
                        <p className="text-base text-white text-shadow">
                          Legendary
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="flex relative flex-col items-start justify-center w-full h-full select-none">
                <img
                  className=" object-cover h-full w-full absolute top-0 left-0"
                  src={`/assets/land-section/${tabSelect.id}/land-section-${
                    tabSelect.describe ? tabSelect.id : "hide"
                  }.webp`}
                  alt="land section"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full h-full absolute left-0 top-0"
        style={{ zIndex: 21 }}
      ></div>
      <div
        className="egg-container absolute z-20 w-96 h-96 top-32 right-[50%] md:top-auto md:right-[29%] md:bottom-[4%]"
        style={{
          width: size.width,
          height: size.height,
          transform: "translateX(50%)",
        }}
      >
        {!isGL && ["beast", "tectos", "mystic"].includes(tabSelect.id) && (
          <div className="absolute bottom-5 w-full">
            <img
              src={image}
              className="mx-auto block"
              style={{
                width: `${imageSize}%`,
                height: `${imageSize}%`,
              }}
              alt="monsterra"
            />
          </div>
        )}
        <div
          id="tectos-egg-player"
          className={`w-full h-full ${
            isGL && tabSelect.id === "tectos" && activeSkin === "skin"
              ? ""
              : "hidden"
          }`}
        ></div>

        <div
          id="mystic-egg-player"
          className={`w-full h-full ${
            isGL && tabSelect.id === "mystic" && activeSkin === "skin"
              ? ""
              : "hidden"
          }`}
        ></div>
        <div
          id="beast-egg-player"
          className={`w-full h-full ${
            isGL && tabSelect.id === "beast" && activeSkin === "skin"
              ? ""
              : "hidden"
          }`}
        ></div>

        <div
          id="celest-egg-player"
          className={`w-full h-full ${
            isGL && tabSelect.id === "celest" && activeSkin === "skin"
              ? ""
              : "hidden"
          }`}
        ></div>

        <div
          id="chaos-egg-player"
          className={`w-full h-full ${
            isGL && tabSelect.id === "chaos" && activeSkin === "skin"
              ? ""
              : "hidden"
          }`}
        ></div>

        <div
          id="tectos-mongen-player"
          className={`w-full h-full ${
            isGL && tabSelect.id === "tectos" && activeSkin === "mongen"
              ? ""
              : "hidden"
          }`}
        ></div>
        <div
          id="mystic-mongen-player"
          className={`w-full h-full ${
            isGL && tabSelect.id === "mystic" && activeSkin === "mongen"
              ? ""
              : "hidden"
          }`}
        ></div>
        <div
          id="celest-mongen-player"
          className={`w-full h-full ${
            isGL && tabSelect.id === "celest" && activeSkin === "mongen"
              ? ""
              : "hidden"
          }`}
        ></div>
        <div
          id="chaos-mongen-player"
          className={`w-full h-full ${
            isGL &&
            tabSelect.id === "chaos" &&
            activeSkin === "mongen" &&
            tabSelect.describe !== null
              ? ""
              : "hidden"
          }`}
        ></div>
        <div
          id="beast-mongen-player"
          className={`w-full h-full ${
            isGL && tabSelect.id === "beast" && activeSkin === "mongen"
              ? ""
              : "hidden"
          }`}
        ></div>
      </div>

      <div className="md:hidden flex flex-col justify-center items-center w-full relative h-[800px]">
        <div className="absolute top-0">
          <img
            className="h-3"
            src="/assets/line.webp"
            alt="line"
            width="882"
            height="13px"
          />
        </div>
        <div className="absolute bottom-0 z-30">
          <img
            className="h-3"
            src="/assets/line.webp"
            alt="line"
            width="882"
            height="13px"
          />
        </div>
        <div className="absolute h-[84px] top-0 z-30 flex flex-col bg-b w-full mt-3 items-center justify-center">
          <div className="w-full relative">
            <button
              onClick={() => clickDropdownLand()}
              className="dropLandbtn relative w-full h-full items-center justify-center flex"
            >
              <img
                src="/assets/land-section/land-section-tab-mb.webp"
                alt="land section"
              />
              <div className="h-full w-full absolute flex flex-row items-center justify-center">
                <p className="font-bold text-tint text-3xl">{tabSelect.name}</p>
                <div className="pl-2 pt-1">
                  <img src="/assets/land-section/drop.webp" alt="land drop" />
                </div>
              </div>
            </button>
            <div
              id="dropdownId"
              className={`dropdownLand-content ${
                showDropdown ? "block" : "hidden"
              } mt-2`}
            >
              {listTab.map((tab, index) => {
                return tabSelect.id === tab.id ? null : (
                  <div key={index}>
                    <div
                      onClick={() => {
                        onChangeTab(tab);
                      }}
                      className="relative w-full mb-3 flex justify-center"
                    >
                      <img
                        src="/assets/land-section/land-section-tab1-mb.webp"
                        alt="land section"
                      />
                      <div className="h-full w-full absolute flex flex-row items-center justify-center">
                        <p className="font-bold text-greyBlur text-3xl">
                          {tab.name}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {tabSelect.describe ? (
          <div className="absolute bottom-0 w-full h-[373px] z-40">
            <div className="relative">
              <img
                className="w-full h-[373px] "
                src="/assets/land-section/blur.webp"
                alt="land section blur"
              />
              <div className="flex flex-col h-[373px] absolute top-0  justify-between text-white pl-5">
                <div className="flex flex-col justify-start">
                  <div
                    className={`w-[120px] ${
                      activeIndex !== 0 && "react-mongen"
                    }`}
                    onClick={() => {
                      onEggClick(0);
                    }}
                  >
                    <img
                      width="100%"
                      height="100%"
                      src={`/assets/land-section/${tabSelect.id}/monster-box.webp`}
                      alt="monster box"
                    />
                  </div>
                  <p
                    className="pb-2 text-3xl text-white pt-4"
                    style={{ fontFamily: "CookieRun1" }}
                  >
                    {tabSelect.describe ? tabSelect.name.toUpperCase() : ""}
                  </p>
                  <p className="text-base text-greyBlur1 w-full pr-4">
                    {tabSelect.describe}
                  </p>
                </div>
                <div className="flex flex-row gap-3 justify-start ">
                  <div
                    className={`flex flex-col items-center ${
                      activeIndex !== 1 && "react-mongen"
                    }`}
                    onClick={() => {
                      onEggClick(1);
                    }}
                  >
                    <div className="w-[48px] ">
                      <img
                        src={`/assets/land-section/${tabSelect.id}/egg-common.webp`}
                        alt="egg common"
                      />
                    </div>
                    <p
                      className="text-white pt-1 text-shadow"
                      style={{ fontSize: "10px" }}
                    >
                      Common
                    </p>
                  </div>
                  <div
                    className={`flex flex-col items-center ${
                      activeIndex !== 2 && "react-mongen"
                    }`}
                    onClick={() => {
                      onEggClick(2);
                    }}
                  >
                    <div className="w-[48px] ">
                      <img
                        src={`/assets/land-section/${tabSelect.id}/egg-uncommon.webp`}
                        alt="egg uncommon"
                      />
                    </div>
                    <p
                      className="text-white pt-1 text-shadow"
                      style={{ fontSize: "10px" }}
                    >
                      Uncommon
                    </p>
                  </div>
                  <div
                    className={`flex flex-col items-center ${
                      activeIndex !== 3 && "react-mongen"
                    }`}
                    onClick={() => {
                      onEggClick(3);
                    }}
                  >
                    <div className="w-[48px] ">
                      <img
                        src={`/assets/land-section/${tabSelect.id}/egg-rare.webp`}
                        alt="egg rare"
                      />
                    </div>
                    <p
                      className="text-white pt-1 text-shadow"
                      style={{ fontSize: "10px" }}
                    >
                      Rare
                    </p>
                  </div>
                  <div
                    className={`flex flex-col items-center ${
                      activeIndex !== 4 && "react-mongen"
                    }`}
                    onClick={() => {
                      onEggClick(4);
                    }}
                  >
                    <div className="w-[48px] ">
                      <img
                        src={`/assets/land-section/${tabSelect.id}/egg-epic.webp`}
                        alt="egg lock"
                      />
                    </div>
                    <p
                      className="text-white pt-1 text-shadow"
                      style={{ fontSize: "10px" }}
                    >
                      Epic
                    </p>
                  </div>
                  <div className="flex flex-col items-center react-mongen">
                    <div className="w-[48px] ">
                      <img
                        src={`/assets/land-section/${tabSelect.id}/egg-lock.webp`}
                        alt="egg lock"
                      />
                    </div>
                    <p
                      className="text-white pt-1 text-shadow"
                      style={{ fontSize: "10px" }}
                    >
                      Legendary
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="h-full w-full">
          {tabSelect.describe ? (
            <img
              className="object-cover h-[510px] w-full"
              src={`/assets/land-section/${tabSelect.id}/land-section-mb-${tabSelect.id}.webp`}
              alt="land section"
            />
          ) : (
            <div className="flex flex-col bg-landLockBg w-full h-full items-center justify-center">
              <div>
                <img
                  src="/assets/land-section/lock.webp"
                  alt="land section lock"
                ></img>
              </div>
              <p className="text-2xl text-center px-3 text-greyBlur1 pt-6">
                This land is so mysterious that we need more time to discover
                !!!
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
